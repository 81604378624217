import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardListItem from "../../card/CardListItem";
import { saveAs } from "file-saver";

export default function ProductListItem({ card }) {
  const dispatch = useDispatch();

  return (
    <CardListItem
      card={card}
      collection="products"
      direction="vertical"
      extra={false}
    >
      <>
        <Button
          floated="right"
          icon="file alternate"
          onClick={() => {
            dispatch(
              openModal({
                modalType: "CommentModal",
                modalProps: { data: card, collectionDB: "products" },
              })
            );
          }}
        />
        <Button.Group size="tiny" floated="right">
          {card.glbUrl && (
            <Button
              // as='a'
              onClick={() => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = (event) => {
                  console.log("loaded...");
                  var blob = xhr.response;
                  saveAs(blob, `${card.title}.glb`);
                };
                xhr.open("GET", card.glbUrl);
                xhr.send();
              }}
              icon="download"
            />
          )}
          <Button
            content="GLB"
            icon="upload"
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "CommitFileFormModal",
                  modalProps: {
                    data: card,
                    field: "glb",
                    collection: "products",
                  },
                })
              );
            }}
          />
        </Button.Group>
        <Button
          size="tiny"
          compact
          icon="desktop"
          onClick={() => {
            dispatch(
              openModal({
                modalType: "GeometryBasicViewerModal",
                modalProps: { data: card, collectionDB: "products" },
              })
            );
          }}
        />
      </>
    </CardListItem>
  );
}

import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { saveAs } from "file-saver";
import CardListItem from "../../card/CardListItem";
import { useMediaQuery } from "react-responsive";
import TableListItem from "../../table/TableListItem";

export default function RoomListItem({ card }) {
  const ismobile = useMediaQuery({ query: "(max-width: 768px)" });
  const dispatch = useDispatch();
  const tablelView = useSelector((state) => state.layout.value.tableView);

  return (
    <>
      {tablelView ? (
        <TableListItem card={card} collection="rooms"></TableListItem>
      ) : (
        <CardListItem
          card={card}
          collection="rooms"
          direction={ismobile ? "vertical" : "horizontal"}
          compact={ismobile ? true : false}
        >
          <>
            <Button.Group size="tiny" floated="right">
              <Button
                // content='Comment'

                icon="file alternate"
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "CommentModal",
                      modalProps: { data: card, collectionDB: "rooms" },
                    })
                  );
                }}
              />
            </Button.Group>
            <Button.Group size="tiny" floated="right">
              {card.pdfUrl && (
                <Button
                  // as='a'
                  onClick={() => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = (event) => {
                      console.log("loaded...");
                      var blob = xhr.response;
                      saveAs(blob, `${card.title}.pdf`);
                    };
                    xhr.open("GET", card.pdfUrl);
                    xhr.send();
                  }}
                  icon="download"
                />
              )}

              <Button
                content="PDF"
                icon="upload"
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "CommitFileFormModal",
                      modalProps: {
                        data: card,
                        field: "pdf",
                        collection: "rooms",
                      },
                    })
                  );
                }}
              />
            </Button.Group>

            <Button.Group size="tiny" floated="right">
              {card.dwgUrl && (
                <Button
                  // as='a'
                  onClick={() => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = (event) => {
                      console.log("loaded...");
                      var blob = xhr.response;
                      saveAs(blob, `${card.title}.dwg`);
                    };
                    xhr.open("GET", card.dwgUrl);
                    xhr.send();
                  }}
                  icon="download"
                />
              )}
              <Button
                content="DWG"
                icon="upload"
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "CommitFileFormModal",
                      modalProps: {
                        data: card,
                        field: "dwg",
                        collection: "rooms",
                      },
                    })
                  );
                }}
              />
            </Button.Group>
            <Button.Group size="tiny" floated="right">
              {card.glbUrl && (
                <Button
                  // as='a'
                  onClick={() => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = (event) => {
                      console.log("loaded...");
                      var blob = xhr.response;
                      saveAs(blob, `${card.title}.glb`);
                    };
                    xhr.open("GET", card.glbUrl);
                    xhr.send();
                  }}
                  icon="download"
                />
              )}
              <Button
                content="GLB"
                icon="upload"
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "CommitFileFormModal",
                      modalProps: {
                        data: card,
                        field: "glb",
                        collection: "rooms",
                      },
                    })
                  );
                }}
              />
            </Button.Group>
          </>
        </CardListItem>
      )}
    </>
  );
}

export const categoryData = [
  { key: "client", text: "Client", value: "client" },
  { key: "design", text: "Design", value: "design" },
  { key: "development", text: "Development", value: "development" },
  { key: "tender", text: "Tender", value: "tender" },
];

export const categorySupplierProducts = [
  { key: "material", text: "Material", value: "material" },
  { key: "ironmongery", text: "Ironmongery", value: "ironmongery" },
  { key: "door", text: "Door", value: "door" },
  { key: "chair", text: "Chair", value: "chair" },
  { key: "table", text: "Table", value: "table" },
  { key: "artwork", text: "Artwork", value: "artwork" },
  { key: "lighting", text: "Lighting", value: "lighting" },
  { key: "plant", text: "Plant", value: "plant" },
  { key: "equipment", text: "Equipment", value: "equipment" },
  { key: "sanitary", text: "Sanitary", value: "sanitary" },
  { key: "wall_finishes", text: "Wall Finishes", value: "wall_finishes" },
  { key: "floor_finishes", text: "Floor Finishes", value: "floor_finishes" },
  {
    key: "ceiling_finishes",
    text: "Ceiling Finishes",
    value: "ceiling_finishes",
  },
  {
    key: "skirting_finishes",
    text: "Skirting Finishes",
    value: "skirting_finishes",
  },
];

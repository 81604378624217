import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardListItem from "../../card/CardListItem";

import {
  clearActionHistory,
  delayClearShapesOnCanvas,
  loadShapesOnCanvas,
} from "../../geometry/threeSlice";

export default function Selection3dListItem({ card }) {
  const dispatch = useDispatch();

  return (
    <CardListItem card={card} collection="selection3ds" direction="horizontal">
      <>
        <Button
          floated="right"
          icon="file alternate"
          onClick={() => {
            dispatch(
              openModal({
                modalType: "CommentModal",
                modalProps: { data: card, collectionDB: "selection3ds" },
              })
            );
          }}
        />
        <Button
          floated="right"
          icon="cloud download"
          onClick={() => {
            dispatch(delayClearShapesOnCanvas()).then(() => {
              dispatch(clearActionHistory());
              dispatch(loadShapesOnCanvas(card.shapesOnCanvas));
            });
          }}
        />
      </>
    </CardListItem>
  );
}

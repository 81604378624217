import React, { useState } from "react";
import { Header } from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import ProductSelectorDashboard from "./ProductSelectorDahsboard";
import ProductSelectorForm from "./ProductSelectorForm";

export default function ProductSelectorFormModal({
  workspaceId,
  spaceId,
  supplierId,
  section,
  selectedDoc,
  collection,
}) {
  const [productCategory, SetProductCategory] = useState(null);
  const [productId, setProductId] = useState(null);
  return (
    <ModalWrapper size='small'>
      <Header content={section.selectionType} />
      <Header content={productCategory} />
      <ProductSelectorForm
        SetProductCategory={SetProductCategory}
        productId={productId}
        workspaceId={workspaceId}
        spaceId={spaceId || null}
        supplierId={supplierId}
        section={section}
        selectedDoc={selectedDoc}
        collection={collection}
      />
      <ProductSelectorDashboard
        productCategory={productCategory}
        setProductId={setProductId}
        workspaceId={workspaceId}
        section={section}
      />
    </ModalWrapper>
  );
}

import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid, Table } from "semantic-ui-react";
import SectionEditDropdown from "../item/SectionEditDropdown";
import CardDashboard from "./CardDashboard";

export default function CardColumn({ children, sections, collection }) {
  const tablelView = useSelector((state) => state.layout.value.tableView);
  return (
    <>
      {sections.map((section) => (
        <Fragment key={section.id}>
          {tablelView ? (
            <>
              <CardDashboard collection={collection} section={section}>
                {children}
              </CardDashboard>
            </>
          ) : (
            <Grid.Column
              // key={section.id}
              //   mobile={16}
              style={{
                backgroundColor: "#494949",

                //   margin: " 7rem 0 0 0",

                position: "relative",
                width: "100%",
                height: "calc(100vh - 9rem - calc(100vh - 100%))",
                scrollSnapAlign: "start",
                borderRadius: "1rem",
                borderStyle: "solid",
                borderColor: "orange",
                borderWidth: "2px",
                overflow: "hidden",
              }}
            >
              <CardDashboard collection={collection} section={section}>
                {children}
              </CardDashboard>
            </Grid.Column>
          )}
        </Fragment>
      ))}
    </>
  );
}

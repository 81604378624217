import React from "react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import ProductForm from "./ProductForm";

export default function ProductFormModal({
  workspaceId,
  spaceId,
  supplierId,
  section,
  selectedDoc,
  collection,
}) {
  return (
    <ModalWrapper size='small'>
      <ProductForm
        workspaceId={workspaceId}
        spaceId={spaceId || null}
        supplierId={supplierId}
        sectionId={section?.id}
        selectedDoc={selectedDoc}
        collection={collection}
      />
    </ModalWrapper>
  );
}

import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  Header,
  Image,
  Item,
  Label,
  Progress,
  Segment,
  Table,
} from "semantic-ui-react";
import MyDigitInput from "../../app/common/form/MyDigitInput";
import MySelectArrayInput from "../../app/common/form/MySelectArrayInput";
import MyTextArea from "../../app/common/form/MyTextArea";
import MyTextInput from "../../app/common/form/MyTextInput";
import { getDifferenceArrayObjects } from "../../app/common/util/util";
import { setEditRowId } from "./tableSlice";

export default function TableForm({ selectedDoc }) {
  const formikProps = useFormikContext();
  const dispatch = useDispatch();

  const selectionsOptions = useSelector((state) => state.room.value);
  const [optionsWF, setOptionsWF] = useState(selectionsOptions.wall_finishes);
  const [optionsFF, setOptionsFF] = useState(selectionsOptions.floor_finishes);
  const [optionsCF, setOptionsCF] = useState(
    selectionsOptions.ceiling_finishes
  );
  const [optionsSF, setOptionsSF] = useState(
    selectionsOptions.skirting_finishes
  );

  useEffect(() => {
    formikProps.values.wall_finishes &&
      setOptionsWF(
        getDifferenceArrayObjects(
          selectionsOptions.wall_finishes,
          formikProps.values.wall_finishes,
          "key"
        )
      );
    formikProps.values.floor_finishes &&
      setOptionsFF(
        getDifferenceArrayObjects(
          selectionsOptions.floor_finishes,
          formikProps.values.floor_finishes,
          "key"
        )
      );
    formikProps.values.ceiling_finishes &&
      setOptionsCF(
        getDifferenceArrayObjects(
          selectionsOptions.ceiling_finishes,
          formikProps.values.ceiling_finishes,
          "key"
        )
      );
    formikProps.values.skirting_finishes &&
      setOptionsSF(
        getDifferenceArrayObjects(
          selectionsOptions.skirting_finishes,
          formikProps.values.skirting_finishes,
          "key"
        )
      );

    return () => {
      console.log("left the form");
    };
  }, [
    formikProps.values.wall_finishes,
    formikProps.values.floor_finishes,
    formikProps.values.ceiling_finishes,
    formikProps.values.skirting_finishes,
  ]);

  return (
    <Table.Row>
      <Table.Cell width={3}>
        <MyTextInput name='title' placeholder='Name' />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyTextArea name='description' placeholder='Description...' rows={1} />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyTextInput name='building' placeholder='FOH/BOH' />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput name='occupancy' placeholder='number of persons' />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput name='level' placeholder='+/- level in mm' />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput name='planned_area' placeholder='Planned Area in sqm' />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput name='area' placeholder='Designed Area in sqm' />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput
          name='height_slabsofit'
          placeholder='Height for Slab Sofit in mm'
        />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput
          name='height_ceiling'
          placeholder='Height for Ceiling in mm'
        />
      </Table.Cell>
      <Table.Cell width={3}>
        <MyDigitInput name='perimeter' placeholder='Perimeter in m' />
      </Table.Cell>
      <Table.Cell width={6}>
        <MySelectArrayInput
          name='wall_finishes'
          placeholder='Wall Finishes'
          options={optionsWF}
          defaultValue={formikProps.values.wall_finishes}
          isMulti
          menuPlacement='auto'
        />
      </Table.Cell>
      <Table.Cell width={6}>
        <MySelectArrayInput
          name='floor_finishes'
          placeholder='Floor Finishes'
          options={optionsFF}
          defaultValue={formikProps.values.floor_finishes}
          isMulti
          menuPlacement='auto'
        />
      </Table.Cell>
      <Table.Cell width={6}>
        <MySelectArrayInput
          name='ceiling_finishes'
          placeholder='Ceiling Finishes'
          options={optionsCF}
          defaultValue={formikProps.values.ceiling_finishes}
          isMulti
          menuPlacement='auto'
        />
      </Table.Cell>
      <Table.Cell width={6}>
        <MySelectArrayInput
          name='skirting_finishes'
          placeholder='Skirting Finishes'
          options={optionsSF}
          defaultValue={formikProps.values.skirting_finishes}
          isMulti
          menuPlacement='auto'
        />
      </Table.Cell>
      <Table.Cell width={3}></Table.Cell>

      <Table.Cell singleLine>
        {formikProps.errors.submit && (
          <Label
            basic
            color='red'
            style={{ marginBottom: 10 }}
            content={formikProps.errors.submit}
          />
        )}
        <Button
          // style={{ backgroundColor: "#ffa600", color: "white" }}
          inverted
          color='green'
          size='tiny'
          compact
          type='submit'
          floated='right'
          icon='check'
          onClick={() => {
            // console.log(formikProps);
          }}
          disabled={
            !formikProps.isValid ||
            !formikProps.dirty ||
            formikProps.isSubmitting
          }
          loading={formikProps.isSubmitting}
        />
        <Button
          inverted
          color='red'
          size='tiny'
          compact
          type='reset'
          floated='right'
          icon='cancel'
          onClick={() => {
            dispatch(setEditRowId(null));
            formikProps.resetForm();
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
}

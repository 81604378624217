import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Grid,
  Header,
  Icon,
  Image,
  Item,
  Segment,
} from "semantic-ui-react";
import { deleteWorkspaceUser } from "../../app/firestore/firestoreService";
import { openModal } from "../../app/common/modals/modalReducer";

export default function UserListItem({ workspaceId, auth, user, adminId }) {
  const dispatch = useDispatch();
  return (
    <>
      <Card className="horizontal">
        <Card.Content>
          <Card.Header>{user.displayName}</Card.Header>
          <Card.Meta>
            <span className="date">--</span>
          </Card.Meta>
          <Card.Description>{user.email}</Card.Description>
          {user.id !== adminId && auth.uid === adminId ? (
            <>
              <Button
                content={"Remove"}
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "UserConfirmModal",
                      modalProps: {
                        user,
                        workspaceId,
                      },
                    })
                  );
                }}
              />
            </>
          ) : null}
        </Card.Content>
        <Image
          style={{ position: "relative" }}
          as={Link}
          to={`/profile/${user.id}`}
        >
          <img alt="" src={user.photoURL || "/assets/user.png"}></img>
          {user.id === adminId ? (
            <>
              <Icon
                style={{ position: "absolute", top: ".15rem", right: "0" }}
                inverted
                color="yellow"
                circular
                name="star"
              />
            </>
          ) : null}
        </Image>
      </Card>
    </>
  );
}

import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useThree } from "@react-three/fiber";

import {
  Bvh,
  CameraControls,
  Environment,
  Html,
  Loader,
} from "@react-three/drei";
import { Kitchen } from "./models/kitchen";
import {
  EffectComposer,
  N8AO,
  Outline,
  Selection,
  ToneMapping,
} from "@react-three/postprocessing";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Menu,
  Segment,
} from "semantic-ui-react";
import ProductMarketDashboard from "../library/product/ProductMarketDashboard";
import {
  clearActionHistory,
  clearShapesOnCanvas,
  deleteLastActionHistory,
  setEditMode,
} from "./threeSlice";
import ExternalModel from "./ExternalModel";
import * as THREE from "three";
import MaterialMenu from "./GeometryHud";
import Selection3dDashboard from "../design/selection3d/Selection3dDashboard";

export default function GeometryViewer() {
  const [canvasScene, setCanvasScene] = useState(null);
  const [sidebarPanel, setSidebarPanel] = useState(0);
  const cameraControlsRef = useRef();
  const camControl = useSelector((state) => state.three.camControl);
  const editMode = useSelector((state) => state.three.editMode);
  const shapesOnCanvas = useSelector((state) => state.three.shapesOnCanvas);
  const actionHistory = useSelector((state) => state.three.actionHistory);
  const hudVisible = useSelector((state) => state.three.hudVisible);

  const dispatch = useDispatch();

  function moveToPosition(object, x, y, z) {
    const parent = object?.parent;
    canvasScene.attach(object);
    object?.position.set(x, y, z);
    console.log(object?.parent);
    parent?.attach(object);
  }

  function undoAction() {
    // const { length, [length - 2]: last } = actionHistory;
    const beforeLastItem =
      actionHistory && actionHistory[actionHistory.length - 2];

    dispatch(deleteLastActionHistory());
    const index = beforeLastItem?.index;

    const rotation = beforeLastItem?.rotation;
    const id = beforeLastItem?.id;

    const object = canvasScene.getObjectById(id);
    const position = new THREE.Vector3(
      beforeLastItem?.position ? beforeLastItem?.position[0] : 0,
      beforeLastItem?.position ? beforeLastItem?.position[1] : 0,
      beforeLastItem?.position ? beforeLastItem?.position[2] : 0
    );

    actionHistory.length > 1 &&
      object &&
      moveToPosition(object, position.x, position.y, position.z);

    object &&
      object.quaternion.set(
        beforeLastItem?.rotation[0],
        beforeLastItem?.rotation[1],
        beforeLastItem?.rotation[2],
        beforeLastItem?.rotation[3]
      );

    console.log({ index, id, position, rotation }, object);
  }

  useEffect(() => {
    dispatch(clearActionHistory());
    dispatch(clearShapesOnCanvas());
    console.log("mount....");
    return () => {
      console.log("unmount....");

      dispatch(clearShapesOnCanvas());
      dispatch(clearActionHistory());
    };
  }, []);

  return (
    <Container fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={4}
            style={{
              background: "white",
              height: "100vh",
              paddingRight: "5px",
              paddingLeft: "5px",
            }}
          >
            <h1>Product details</h1>
            <Divider horizontal>Details</Divider>
            <Divider horizontal>
              <Button
                onClick={() => {
                  setSidebarPanel(0);
                }}
                attached="left"
              >
                Products
              </Button>
              <Button
                onClick={() => {
                  setSidebarPanel(1);
                }}
                attached="right"
              >
                3D Selections
              </Button>
            </Divider>

            {sidebarPanel === 0 && <ProductMarketDashboard />}
            {sidebarPanel === 1 && <Selection3dDashboard />}
          </Grid.Column>

          <Grid.Column stretched width={12}>
            <h4>Tools</h4>
            <Menu text style={{ maxHeight: "5rem" }} attached="top" inverted>
              <Menu.Item>
                <Button.Group>
                  <Button
                    content={"erase"}
                    onClick={() => {
                      dispatch(clearShapesOnCanvas());
                    }}
                  />
                </Button.Group>
                <Button.Group>
                  <Button
                    toggle
                    active={editMode}
                    content={"edit"}
                    onClick={() => {
                      dispatch(setEditMode(!editMode));
                    }}
                  />
                  <Button
                    disabled={actionHistory.length < 1}
                    content={"undo"}
                    onClick={() => {
                      undoAction();
                    }}
                  />
                </Button.Group>
              </Menu.Item>
            </Menu>
            <Segment basic attached="bottom" style={{ padding: 0 }}>
              <Canvas
                camera={{ position: [3, 1.75, 3] }}
                style={{ width: "100%", height: "100%", margin: "0" }}
                onCreated={({ scene }) => {
                  setCanvasScene(scene);
                }}
              >
                <color attach="background" args={["#111"]} />

                <Bvh firstHitOnly>
                  <Selection>
                    <Suspense
                      fallback={
                        <Html>
                          <Loader />
                        </Html>
                      }
                    >
                      <Effects />
                      <Kitchen cameraControlsRef={cameraControlsRef} />
                    </Suspense>

                    {hudVisible && (
                      <Suspense
                        fallback={
                          <Html>
                            <Loader />
                          </Html>
                        }
                      >
                        {editMode && <MaterialMenu />}
                      </Suspense>
                    )}

                    {shapesOnCanvas.map((card, index) => (
                      <Suspense
                        key={index}
                        fallback={
                          <Html>
                            <Loader />
                          </Html>
                        }
                      >
                        <ExternalModel
                          card={card}
                          key={index}
                          cameraControlsRef={cameraControlsRef}
                          index={index}
                          canvasScene={canvasScene}
                        />
                      </Suspense>
                    ))}
                  </Selection>
                </Bvh>

                <Environment
                  files="/hdri/kloofendal_misty_morning_puresky_1k.hdr"
                  ground={{
                    height: 3,
                    radius: 115,
                    scale: 100,
                  }}
                />
                <CameraControls
                  enabled={camControl}
                  ref={cameraControlsRef}
                  minPolarAngle={0}
                  maxPolarAngle={Math.PI / 2}
                />
              </Canvas>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

function Effects() {
  const { size } = useThree();

  return (
    <EffectComposer
      stencilBuffer
      disableNormalPass
      autoClear={false}
      multisampling={4}
    >
      <N8AO
        halfRes
        aoSamples={5}
        aoRadius={0.4}
        distanceFalloff={0.75}
        intensity={1}
      />
      <Outline
        visibleEdgeColor="white"
        hiddenEdgeColor="white"
        blur
        width={size.width * 1.25}
        edgeStrength={10}
      />
      <ToneMapping />
    </EffectComposer>
  );
}

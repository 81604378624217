import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Item,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { decreament, increament } from "./testReducer";

export default function Sandbox(props) {
  const data = useSelector((state) => state.test.data);
  const [target, setTarget] = useState(null);
  const { loading } = useSelector((state) => state.async);
  const dispatch = useDispatch();

  return (
    <>
      {/* <Container>
        <Grid
          columns={3}
          divided
          style={{
            backgroundColor: "grey",
            height: "calc(100vh-1em)",
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            display: "grid",
            gridTemplateColumns: "repeat(3, 100%)",
          }}
        >
          <Grid.Column
            style={{ width: "100%", height: "100vh", scrollSnapAlign: "start" }}
            color='red'
          ></Grid.Column>
          <Grid.Column
            style={{ width: "100%", height: "100vh", scrollSnapAlign: "start" }}
            color='green'
          ></Grid.Column>
          <Grid.Column
            style={{ width: "100%", height: "100vh", scrollSnapAlign: "start" }}
            color='yellow'
          ></Grid.Column>
        </Grid>
      </Container> */}
      {/* <Container style={{ marginTop: "20", height: "calc(100vh-1em)" }}>
        <Button
          onClick={() =>
            dispatch(
              openModal({ modalType: "TestModal", modalProps: { data: 33 } })
            )
          }
          content='Open Modal'
          color='teal'
        />

        <h3> data is: {data} </h3>
        <Button
          loading={loading && target === "increment"}
          name='increment'
          content='increment'
          color='green'
          onClick={(e) => {
            setTarget(e.target.name);
            dispatch(increament(5));
          }}
        />
        <Button
          name='decrement'
          loading={loading && target === "decrement"}
          content='decrement'
          color='red'
          onClick={(e) => {
            setTarget(e.target.name);
            dispatch(decreament(10));
          }}
        />
        <Card fluid>
          <Card.Content style={{ padding: "0" }}>
            <Item.Group unstackable>
              <Item>
                <Card.Content style={{ padding: "1rem" }}>
                  <Card.Header>Matthew</Card.Header>

                  <Card.Description>
                    Matthew is a musician living in Nashville.
                  </Card.Description>
                  <Card.Meta>
                    <span className='date'>Joined in 2015</span>
                  </Card.Meta>
                </Card.Content>
              </Item>
            </Item.Group>
          </Card.Content>
          <Card.Content extra>
            <Button content='PDF' />
            <Card.Meta content='Husni Hawash' />
          </Card.Content>
        </Card>
        <Card fluid>
          <Card.Content>
            <Card.Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Church Mutual Worker's Compensation Claim</div>
              <div>#ID-1234567</div>
            </Card.Header>
            <Card.Meta
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>
                Date Recieved: <span style={{ color: "red" }}>07/20/2018</span>
                <span style={{ marginLeft: "30px" }}>
                  Account Number: 76543210213
                </span>
              </p>
              <Button color='red' size='mini' content='Urgent' />
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Content className='card-main'>
            <div className='card-cont'>
              <Card.Content className='card-main-content'>
                <div className='card-inner-content'>
                  <Card.Header>Matthew</Card.Header>
                  <Card.Description>
                    Lorem Ipsum is simply dummy text of the printing and
                  </Card.Description>
                </div>
                <div className='card-extra'>
                  <Card.Meta>Joined in 2015</Card.Meta>
                  <Button content='PDF' />
                </div>
              </Card.Content>
            </div>
            <div className='card-hero'>
              <img className='card-img' src='/assets/villa.jpeg'></img>
            </div>
          </Card.Content>
        </Card>
        <Card className='horizontal' fluid>
          <Card.Content>
            <Card.Header>Matthew</Card.Header>

            <Card.Description>
              Matthew is a musician living in Nashville.
            </Card.Description>
            <Card.Meta>
              <span className='date'>Joined in 2015</span>
              <Button content='PDF' floated='right' />
            </Card.Meta>
          </Card.Content>
          <Image size='medium' src='/assets/villa.jpeg' wrapped ui={false} />
        </Card>
      </Container> */}

      {/* <Container
        style={{
          backgroundColor: "grey",
          height: "calc(100vh-1em)",
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          display: "grid",
          gridTemplateColumns: "repeat(3, 100%)",
        }}
      >
        <Grid
          columns={3}
          divided

          // style={{
          //   backgroundColor: "grey",
          //   height: "calc(100vh)",
          //   display: "grid",
          // }}
        >
          <Grid.Row color='blue' style={{ height: "90px" }}></Grid.Row>
          <Grid.Row
            color='orange'
            style={{ height: "calc(100vh - 90px)" }}
          ></Grid.Row> */}
      {/* <Grid.Column
            style={{ width: "30%", height: "calc(100vh-90px)" }}
            color='red'
          ></Grid.Column>
          <Grid.Column
            style={{ width: "70%", height: "calc(100vh-90px)" }}
            color='green'
          ></Grid.Column> */}
      {/* </Grid>
      </Container> */}

      {/* <Container fluid>
        <Grid
          fluid
          columns={2}
          divided
          style={{
            backgroundColor: "grey",
            height: "calc(100vh)",
            // display: "grid",
          }}
        >
          <Grid.Row stretched>
            <Grid.Column color="green"></Grid.Column>
            <Grid.Column>
              <Grid.Row color="yellow"></Grid.Row>
            </Grid.Column>
          </Grid.Row> */}
      {/* <Grid.Column
            style={{ width: "30%", height: "100vh" }}
            color="red"
          ></Grid.Column>
          <Grid.Column style={{ width: "70%", height: "100vh" }} color="green">
            <Grid.Row color="blue" style={{ height: "90px" }}></Grid.Row>
            <Grid.Row
              color="orange"
              style={{
                height: "calc(100vh - 90px)",
                padding: "0",
                zIndex: "90",
              }}
            ></Grid.Row>
          </Grid.Column> */}
      {/* </Grid>
      </Container> */}

      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              width={4}
              style={{
                background: "gray",
                height: "100vh",
              }}
            >
              <h1>Product details</h1>
            </Grid.Column>

            <Grid.Column stretched width={12}>
              <Menu style={{ maxHeight: "90px" }} attached="top" inverted>
                <h1>Tools</h1>
              </Menu>
              <Segment attached="bottom">
                <h1>3D Area</h1>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
}

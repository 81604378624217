import React, { Fragment, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Label,
  Select,
  Table,
} from "semantic-ui-react";
import { format } from "date-fns";

import ItemEditDropdown from "../item/ItemEditDropdown";
import TableForm from "./TableForm";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setEditRowId } from "./tableSlice";
import {
  cancelDocById,
  deleteDocFromFirestore,
} from "../../app/firestore/firestoreService";
import { openModal } from "../../app/common/modals/modalReducer";

export default function TableListItem({ card, collection }) {
  const [isRowEntered, setRowEntered] = useState(false);
  const formikProps = useFormikContext();
  const dispatch = useDispatch();

  const editRowId = useSelector((state) => state.table.value.editRowId);

  return (
    <>
      {editRowId === card.id ? (
        <TableForm selectedDoc={card} />
      ) : (
        <Table.Row
          key={card.id}
          onMouseEnter={() => {
            setRowEntered(true);
          }}
          onMouseLeave={() => {
            setRowEntered(false);
          }}
        >
          <Table.Cell
            className="selectable"
            onClick={(e) => {
              console.log(e.target.textContent);
            }}
            style={{ minWidth: "10rem", padding: "1rem" }}
          >
            {card.title}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "20rem" }}>
            {card.description}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>{card.building}</Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>{card.occupancy}</Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>{card.level}</Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>
            {card.planned_area}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>{card.area}</Table.Cell>

          <Table.Cell style={{ minWidth: "5rem" }}>
            {card.height_slabsofit}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>
            {card.height_ceiling}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>{card.perimeter}</Table.Cell>

          <Table.Cell style={{ minWidth: "20rem" }}>
            {card.wall_finishes &&
              card.wall_finishes.map((item) => {
                return (
                  <Label image key={item.key} style={{ marginTop: "0.4rem" }}>
                    <Image
                      src={item.image.src}
                      style={{ aspectRatio: "1/1" }}
                    />

                    {item.label}
                  </Label>
                );
              })}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "20rem" }}>
            {card.floor_finishes &&
              card.floor_finishes.map((item) => {
                return (
                  <Label image key={item.key} style={{ marginTop: "0.4rem" }}>
                    <Image
                      src={item.image.src}
                      style={{ aspectRatio: "1/1" }}
                    />
                    {item.label}
                  </Label>
                );
              })}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "20rem" }}>
            {card.ceiling_finishes &&
              card.ceiling_finishes.map((item) => {
                return (
                  <Label image key={item.key} style={{ marginTop: "0.4rem" }}>
                    <Image
                      src={item.image.src}
                      style={{ aspectRatio: "1/1" }}
                    />
                    {item.label}
                  </Label>
                );
              })}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "20rem" }}>
            {card.skirting_finishes &&
              card.skirting_finishes.map((item) => {
                return (
                  <Label image key={item.key} style={{ marginTop: "0.4rem" }}>
                    <Image
                      src={item.image.src}
                      style={{ aspectRatio: "1/1" }}
                    />
                    {item.label}
                  </Label>
                );
              })}
          </Table.Cell>

          <Table.Cell collapsing textAlign="right">
            {card.createdAt &&
              format(card.createdAt.toDate(), "MMM d, yyyy hh:mm a")}
          </Table.Cell>
          <Table.Cell style={{ minWidth: "5rem" }}>
            {isRowEntered ? (
              <>
                <Button
                  size="tiny"
                  compact
                  icon="desktop"
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalType: "GeometryBasicViewerModal",
                        modalProps: { data: card, collectionDB: "rooms" },
                      })
                    );
                  }}
                />
                <Button
                  size="tiny"
                  compact
                  icon="edit"
                  onClick={() => {
                    dispatch(setEditRowId(card.id));
                    formikProps.setFieldValue("title", card.title);
                    formikProps.setFieldValue("description", card.description);
                    formikProps.setFieldValue("building", card.building);
                    formikProps.setFieldValue("occupancy", card.occupancy);
                    formikProps.setFieldValue("level", card.level);
                    formikProps.setFieldValue(
                      "planned_area",
                      card.planned_area
                    );
                    formikProps.setFieldValue("area", card.area);

                    formikProps.setFieldValue(
                      "height_slabsofit",
                      card.height_slabsofit
                    );
                    formikProps.setFieldValue(
                      "height_ceiling",
                      card.height_ceiling
                    );
                    formikProps.setFieldValue("perimeter", card.perimeter);
                    formikProps.setFieldValue(
                      "wall_finishes",
                      card.wall_finishes
                    );
                    formikProps.setFieldValue(
                      "floor_finishes",
                      card.floor_finishes
                    );
                    formikProps.setFieldValue(
                      "ceiling_finishes",
                      card.ceiling_finishes
                    );
                    formikProps.setFieldValue(
                      "skirting_finishes",
                      card.skirting_finishes
                    );
                    // console.log("from card", card.wall_finishes || []);
                  }}
                />
                {/* <ItemEditDropdown card={card} collection={collection} /> */}
                {card.isCancelled ? (
                  <>
                    <Button
                      size="tiny"
                      compact
                      icon="unhide"
                      onClick={() => {
                        dispatch(cancelDocById(card.id, collection, false));
                      }}
                    />
                    <Button
                      size="tiny"
                      color="red"
                      compact
                      icon="trash"
                      onClick={() => {
                        dispatch(deleteDocFromFirestore(card, collection));
                      }}
                    />
                  </>
                ) : (
                  <Button
                    size="tiny"
                    compact
                    icon="hide"
                    onClick={() => {
                      dispatch(cancelDocById(card.id, collection, true));
                    }}
                  />
                )}
              </>
            ) : null}
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

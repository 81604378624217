import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import {
  cancelDocById,
  deleteDocFromFirestore,
} from "../../app/firestore/firestoreService";

export default function ItemEditDropdown({ card, collection }) {
  const dispatch = useDispatch();
  return (
    <Dropdown
      direction="left"
      className="right floated"
      //   style={{ color: "black" }}
      icon="ellipsis vertical"
    >
      <Dropdown.Menu>
        {card.isCancelled ? (
          <>
            <Dropdown.Item
              // as={Link}
              // to={`/`}
              text="Delete"
              icon="trash"
              onClick={() => {
                dispatch(deleteDocFromFirestore(card, collection));
              }}
            />
            <Dropdown.Item
              // as={Link}
              // to={`/`}
              text="Restore"
              icon="unhide"
              onClick={() => {
                dispatch(cancelDocById(card.id, collection, false));
              }}
            />
          </>
        ) : (
          <Dropdown.Item
            // as={Link}
            // to={`/`}
            text="Cancel"
            icon="hide"
            onClick={() => {
              dispatch(cancelDocById(card.id, collection, true));
            }}
          />
        )}

        <Dropdown.Item
          text="Edit"
          icon="settings"
          onClick={() => {
            dispatch(
              openModal({
                modalType: `${
                  collection === "suppliers"
                    ? "Supplier"
                    : collection === "products"
                    ? "Product"
                    : collection === "selection3ds"
                    ? "Selection3d"
                    : "Card"
                }FormModal`,
                modalProps: {
                  workspaceId: card.workspaceId,
                  spaceId: card.spaceId,
                  selectedDoc: card,
                  collection: collection,
                },
              })
            );
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { delay } from "../../app/common/util/util";

export const delayClearShapesOnCanvas = createAsyncThunk(
  "three/delayClearShapesOnCanvas",
  async (_, thunkAPI) => {
    // const { getState } = thunkAPI;

    return delay(60).then(function () {
      return Promise.resolve();
    });
  }
);

const initialState = {
  camControl: true,
  editMode: false,
  loadShapes: false,
  hudVisible: false,
  hudOpacity: 0,
  selectedObj: null,
  shapesOnCanvas: [],
  actionHistory: [],

  seat: {
    availableColors: ["#151A22", "#465048", "#BD916F", "#C0B283", "#f3f0e6"],
    currentColor: "#f3f0e6",
    title: "IKEA Chair :: GRÖNSTA",
    price: "€59.95",
    description: "Chair, in/outdoor white",
    url: "https://www.ikea.com/nl/en/p/groensta-chair-in-outdoor-white-70519763/",
  },
  table: {
    availableColors: ["#AFB796", "gray", "#F2E5D5", "#DAB79F", "#6А7562"],
    currentColor: "#DAB79F",
    title: "IKEA Table :: MELLANSEL",
    price: "€649",
    description: "Extendable table, oak veneer",
    url: "https://www.ikea.com/nl/en/p/mellansel-extendable-table-oak-veneer-s99392297/",
  },
  wall: {
    // availableColors: ["#AFB796", "gray", "#F2E5D5", "#DAB79F", "#6А7562"],
    availableColors: ["#416883", "#696967", "#9F9B7C", "#BB7D69", "#F0EFE9"],
    currentColor: "#F0EFE9",
    title: "Jotun 4947 True Blue",
    price: "€200",
    description: "Fenomastic My Home Rich Matt",
    url: "https://www.jotun.com/me-en/decorative/interior/colours/colourcards/jotun-colour-collection-2024",
  },
  sofa: {
    availableColors: ["#AFB796", "gray", "#F2E5D5", "#DAB79F", "#6А7562"],
    currentColor: "#DAB79F",
    title: "IKEA Sofa :: KRYLBO",
    price: "€890,00",
    description: "Extendable sofa, leather",
    url: "https://www.ikea.com/nl/en/p/landskrona-2-seat-sofa-grann-bomstad-golden-brown-metal-s19270266/",
  },
  chair: {
    availableColors: ["#AFB796", "gray", "#F2E5D5", "#DAB79F", "#6А7562"],
    currentColor: "#DAB79F",
    title: "IKEA Chair :: TOSSBERG",
    price: "€890,00",
    description: "Chair, metal black/grey",
    url: "https://www.ikea.com/nl/en/p/tossberg-chair-metal-black-grey-90435324/",
  },
};

const threeSlice = createSlice({
  name: "three",
  initialState,
  extraReducers: {
    [delayClearShapesOnCanvas.pending]: (state, action) => {
      state.shapesOnCanvas = [];
      console.log(action);
    },
    [delayClearShapesOnCanvas.fulfilled]: (state, action) => {
      console.log(action);
    },
    [delayClearShapesOnCanvas.rejected]: (state, action) => {
      console.log(action);
    },
  },
  reducers: {
    setCamControl: (state, action) => {
      state.camControl = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setLoadShapes: (state, action) => {
      state.loadShapes = action.payload;
    },
    toggleHud: (state, action) => {
      state.hudVisible = action.payload;
    },
    setHudOpacity: (state, action) => {
      state.hudOpacity = action.payload;
    },
    setSeatColor: (state, action) => {
      state.seat.currentColor = action.payload;
    },
    setTableColor: (state, action) => {
      state.table.currentColor = action.payload;
    },
    setWallColor: (state, action) => {
      state.wall.currentColor = action.payload;
    },
    setSelectedObj: (state, action) => {
      state.selectedObj = action.payload;
    },
    setShapesOnCanvas: (state, action) => {
      // action.payload.deleted = "false";
      const obj1 = { isDeleted: false };
      const merged = { ...action.payload, ...obj1 };
      state.shapesOnCanvas = [...state.shapesOnCanvas, merged];
      // state.shapesOnCanvas = [...state.shapesOnCanvas, action.payload];
    },
    setActionHistory: (state, action) => {
      if (
        isEqual(
          state.actionHistory[state.actionHistory?.length - 1],
          action.payload
        )
      ) {
        return;
      } else {
        state.actionHistory = [...state.actionHistory, action.payload];
      }
    },
    deleteLastActionHistory: (state) => {
      state.actionHistory.splice(-1);
    },
    clearActionHistory: (state) => {
      state.actionHistory = [];
    },

    filterShapesOnCanvas: (state, action) => {
      state.shapesOnCanvas = state.shapesOnCanvas.map((item, index) => {
        const obj1 = { isDeleted: true };
        const merged = { ...item, ...obj1 };
        if (index === action.payload) {
          return merged;
        } else {
          return item;
        }
      });
    },
    setShapePosition: (state, action) => {
      state.shapesOnCanvas = state.shapesOnCanvas.map((item, index) => {
        const obj1 = {
          position: action.payload.position,
          rotation: action.payload.rotation,
        };
        const merged = { ...item, ...obj1 };
        if (index === action.payload.index) {
          return merged;
        } else {
          return item;
        }
      });
    },
    setShapeTransformation: (state, action) => {
      state.shapesOnCanvas = state.shapesOnCanvas.map((item, index) => {
        const obj1 = {
          position: action.payload.position,
          rotation: action.payload.rotation,
        };
        const merged = { ...item, ...obj1 };
        if (item.mesh_uuid === action.payload.uuid) {
          return merged;
        } else {
          return item;
        }
      });
    },
    setShapeMeshId: (state, action) => {
      state.shapesOnCanvas = state.shapesOnCanvas.map((item, index) => {
        const obj1 = {
          mesh_uuid: action.payload.uuid,
        };
        const merged = { ...item, ...obj1 };
        if (index === action.payload.index) {
          return merged;
        } else {
          return item;
        }
      });
    },
    clearShapesOnCanvas: (state) => {
      state.shapesOnCanvas = [];
    },
    loadShapesOnCanvas: (state, action) => {
      state.shapesOnCanvas = action.payload;
    },
  },
});

export const {
  setCamControl,
  setEditMode,
  setSeatColor,
  setTableColor,
  setWallColor,
  toggleHud,
  setSelectedObj,
  setHudOpacity,
  setShapesOnCanvas,
  filterShapesOnCanvas,
  clearShapesOnCanvas,
  setLoadShapes,
  setShapePosition,
  setShapeMeshId,
  setShapeTransformation,
  setActionHistory,
  deleteLastActionHistory,
  clearActionHistory,
  loadShapesOnCanvas,
} = threeSlice.actions;
export default threeSlice.reducer;

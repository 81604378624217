import React from "react";
import { Card, Comment, Grid, Header, Image, Item } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CommentForm from "../comment/CommentForm";
import CommentList from "../comment/CommentList";
import ProductDetails from "../library/product/ProductDetails";

export default function CardModal({ data, collectionFolder }) {
  return (
    <ModalWrapper size='small'>
      <Item.Group>
        <Item>
          <Image
            as='img'
            loading='lazy'
            rounded
            src={data.imageResizedLargeUrl}
            style={{
              inlineSize: "100%",
              aspectRatio: "16 / 9",
              objectFit: "cover",
            }}
          />
        </Item>
        <Item>
          <Item.Content>
            <Item.Meta>{data.title}</Item.Meta>
            <Item.Description></Item.Description>
            <Item.Extra>{data.description}</Item.Extra>
          </Item.Content>
        </Item>
        {data.pricesupply && (
          <>
            <Grid columns={4} className='very compact centered'>
              <Grid.Row>
                <Grid.Column>
                  <Header as='h5'>
                    Material
                    <Header.Subheader>{data.material}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Finish
                    <Header.Subheader>{data.finish}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Color
                    <Header.Subheader>{data.color}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Brand
                    <Header.Subheader>{data.brand}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Collection
                    <Header.Subheader>{data.collection}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Size
                    <Header.Subheader>{data.size}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Price Supply
                    <Header.Subheader>{data.pricesupply}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Price Installation
                    <Header.Subheader>
                      {data.priceinstallation}
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
        {data.company && (
          <>
            <Grid columns={3} className='very compact '>
              <Grid.Row>
                <Grid.Column>
                  <Header as='h5'>
                    Company Name
                    <Header.Subheader>{data.company}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Address
                    <Header.Subheader>{data.address}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Email
                    <Header.Subheader>{data.email}</Header.Subheader>
                  </Header>
                </Grid.Column>

                <Grid.Column>
                  <Header as='h5'>
                    Telephone
                    <Header.Subheader>{data.telephone}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Mobile
                    <Header.Subheader>{data.mobile}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Agent
                    <Header.Subheader>{data.agent}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h5'>
                    Website
                    <Header.Subheader>{data.website}</Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </Item.Group>
      {data.productId && <ProductDetails docId={data.productId} />}

      <CommentForm
        docId={data.id}
        workspaceId={data.workspaceId}
        spaceId={data.spaceId}
        collectionFolder={collectionFolder}
      />
      <CommentList docId={data.id} />
    </ModalWrapper>
  );
}

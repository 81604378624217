import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Dropdown, Grid, Icon, Menu } from "semantic-ui-react";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";
import { Link, NavLink, useParams } from "react-router-dom";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { updateUserProfileCurrentWorkspace } from "../auth/authActions";
import { setWorkspaceUsers } from "../user/usersSlice";
import { useHistory } from "react-router-dom";
export default function NavBar({ setVisible, visible }) {
  const { authenticated, currentUser } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.firebase);
  const { workspaceId, spaceId } = useParams();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  let history = useHistory();

  const selectedWorkspace = useSelector(
    (state) =>
      workspaceId && state.firestore.ordered[`workspaces/${workspaceId}`]
  );
  const selectedSpace = useSelector(
    (state) => spaceId && state.firestore.ordered[`spaces/${spaceId}`]
  );

  let spacesQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    spacesQuery = {
      collection: "spaces",
      orderBy: ["title", "asc"],
      storeAs: `spaces/${profile.currentWorkspace}`,
      where: [
        ["workspaceId", "==", profile.currentWorkspace],
        ["isCancelled", "==", false],
      ],
    };
  }

  const selectedWorkspaceQuery = {
    collection: "workspaces",
    doc: workspaceId,
    storeAs: `workspaces/${workspaceId}`,
  };
  const selectedSpaceQuery = {
    collection: "spaces",
    doc: spaceId,
    storeAs: `spaces/${spaceId}`,
  };

  useFirestoreConnect(() => profile.currentWorkspace && [spacesQuery]);
  useFirestoreConnect(() => workspaceId && [selectedWorkspaceQuery]);
  useFirestoreConnect(() => spaceId && [selectedSpaceQuery]);
  async function handleMakeDefault() {
    const userUpdate = {
      uid: currentUser.uid,
      currentSpace: spaceId,
      currentWorkspace: workspaceId,
    };
    console.log(userUpdate);
    try {
      await updateUserProfileCurrentWorkspace(userUpdate);
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message);
    }
  }

  useEffect(() => {
    dispatch(
      setWorkspaceUsers(
        selectedWorkspace ? selectedWorkspace[0]?.members : null
      )
    );
  }, [selectedWorkspace]);

  const spaces = useSelector(
    (state) => state.firestore.ordered[`spaces/${profile.currentWorkspace}`]
  );

  let spacesOptions = [];
  spacesOptions = spaces?.map((item) => ({
    key: item.id,
    text: item.title,
    value: item.id,
    // image: { avatar: true, src: item.imageResizedSmallUrl },
  }));

  return (
    <>
      <Menu
        className="navbar"
        inverted
        borderless
        fixed="top"
        //       style={{
        //         backgroundImage: `linear-gradient(
        //   135deg,
        //   #1F2024 0%,

        //   #28292B 75%
        // )`,
        //       }}
      >
        <Container>
          <Menu.Item
            style={{ width: "40px" }}
            icon={visible ? "times" : "bars"}
            onClick={() => setVisible(!visible)}
          />

          <Menu.Item as={NavLink} to="/home" icon="home" />
          {isLoaded(selectedSpace) && !isEmpty(selectedSpace) ? (
            <Menu.Item
              position="left"
              // content={selectedSpace[0].title}
              icon="target"
              as={Link}
              to={`/workspaces/${workspaceId}/spaces/${spaceId}/`}
            />
          ) : (
            <></>
          )}

          {isLoaded(selectedWorkspace) && !isEmpty(selectedWorkspace) ? (
            <Menu.Item position="left">
              <Dropdown pointing="top right" text={selectedWorkspace[0].title}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={handleMakeDefault}
                    text="Make Default"
                    icon="star"
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    as={Link}
                    to={`/workspaces/${workspaceId}/spaces`}
                  >
                    <Icon name="building outline" />
                    Projects
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/workspaces/${workspaceId}/users`}
                  >
                    <Icon name="users" />
                    Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/workspaces/${workspaceId}/finance`}
                  >
                    <Icon name="money" />
                    Finance
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Icon name="list" />

                    <Dropdown text="Library">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={Link}
                          to={`/workspaces/${workspaceId}/suppliers`}
                        >
                          Suppliers
                        </Dropdown.Item>
                        <Dropdown.Item>Materials</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          ) : (
            <></>
          )}

          {selectedWorkspace && (
            <Menu.Item position="left">
              {spacesOptions && (
                <Dropdown
                  icon="search"
                  options={spacesOptions}
                  placeholder="Select Project"
                  search
                  defaultValue={
                    spacesOptions
                      ? spacesOptions.filter((item) => {
                          return item.value === spaceId;
                        })[0]?.value
                      : null
                  }
                  onChange={(event, data) => {
                    history.push(
                      `/workspaces/${workspaceId}/spaces/${data.value}/`
                    );
                  }}
                />
              )}
            </Menu.Item>
          )}

          <Menu.Item>
            <Grid>
              <Grid.Column only="large screen"></Grid.Column>
            </Grid>
          </Menu.Item>
          {/* <Menu.Item content="Arjuan" position="left" /> */}
          {/* <Menu.Item as={NavLink} to='/workspaces' name='workspaces' />
          {/* <Menu.Item as={NavLink} to='/sandbox' name='Sandbox' /> */}

          {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
        </Container>
      </Menu>
    </>
  );
}

import { Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";

import {
  Button,
  Card,
  Checkbox,
  Container,
  Dropdown,
  Grid,
  Header,
  Label,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import * as Yup from "yup";
import { openModal } from "../../app/common/modals/modalReducer";
import {
  cancelDocById,
  deleteDocFromFirestore,
  updateCard,
} from "../../app/firestore/firestoreService";
import SectionEditDropdown from "../item/SectionEditDropdown";
import SectionHeader from "../section/SectionHeader";
import { setEditRowId } from "../table/tableSlice";

const initialValues = {
  title: "",
  description: "",
  area: 0,
  wall_finishes: "",
  floor_finishes: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("you must provide a title"),
  description: Yup.string().required("you must provide a description"),
});

export default function CardDashboard({ section, collection, children }) {
  const { spaceId, supplierId, workspaceId } = useParams();
  const sectionId = section.id;

  //use workspaceId instead of spaceId in query if this dashboard was running at workspace level for library as example.
  const [cancelView, setCancelView] = useState(false);
  const [isCardEntered, setCardEntered] = useState(false);
  const dispatch = useDispatch();
  const formikProps = useFormikContext();

  const auth = useSelector((state) => state.firebase.auth);
  let cardQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    cardQuery = {
      collection: collection,
      orderBy: ["createdAt", "desc"],
      storeAs: `${collection}/${spaceId ? spaceId : workspaceId}/${section.id}`,
      where: [
        ["sectionId", "==", section.id],
        supplierId ? ["supplierId", "==", supplierId || null] : null,
        spaceId ? ["spaceId", "==", spaceId || null] : null,
        ["workspaceId", "==", workspaceId],
        ["isCancelled", "==", cancelView],
      ].filter(Boolean),
    };
  }

  useFirestoreConnect(() => [cardQuery]);

  const cards = useSelector(
    (state) =>
      state.firestore.ordered[
        `${collection}/${spaceId ? spaceId : workspaceId}/${section.id}`
      ]
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { cards, sectionId });
    }
    return child;
  });

  // console.log("CARDs:", cards);
  const tablelView = useSelector((state) => state.layout.value.tableView);
  const editRowId = useSelector((state) => state.table.value.editRowId);

  return (
    <>
      {tablelView ? (
        <>
          <>
            <Formik
              enableReinitialize
              initialValues={formikProps ? formikProps.values : initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                const { file, ...valueWithNoFile } = values;

                try {
                  await dispatch(
                    updateCard(valueWithNoFile, collection, editRowId)
                  );
                  dispatch(setEditRowId(null));
                  setSubmitting(false);
                } catch (error) {
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, isValid, dirty, errors, setFieldValue }) => (
                <Form className='ui form'>
                  <Table
                    className='rds'
                    style={{ width: "100%" }}
                    inverted
                    unstackable
                    celled
                    striped
                    compact
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          colSpan='16'
                          onMouseEnter={() => {
                            setCardEntered(true);
                          }}
                          onMouseLeave={() => {
                            setCardEntered(false);
                          }}
                        >
                          <SectionHeader
                            section={section}
                            collection={collection}
                            workspaceId={workspaceId}
                            spaceId={spaceId}
                            supplierId={supplierId}
                            setCancelView={setCancelView}
                            cancelView={cancelView}
                            isCardEntered={isCardEntered}
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Building</Table.HeaderCell>
                        <Table.HeaderCell>Occupancy</Table.HeaderCell>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Planned Area</Table.HeaderCell>
                        <Table.HeaderCell>Area</Table.HeaderCell>
                        <Table.HeaderCell>Height SlabSofit</Table.HeaderCell>
                        <Table.HeaderCell>Height Ceiling</Table.HeaderCell>
                        <Table.HeaderCell>Perimeter</Table.HeaderCell>
                        <Table.HeaderCell>Wall Finish</Table.HeaderCell>
                        <Table.HeaderCell>Floor Finish</Table.HeaderCell>
                        <Table.HeaderCell>Ceiling Finish</Table.HeaderCell>
                        <Table.HeaderCell>Skirting Finish</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Tools</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    {isLoaded(cards) && !isEmpty(cards) && (
                      <Table.Body>{childrenWithProps}</Table.Body>
                    )}
                  </Table>
                </Form>
              )}
            </Formik>
          </>
        </>
      ) : (
        <>
          <Segment
            basic
            inverted
            style={{
              width: "100%",
              borderRadius: "0.5rem",
              paddingLeft: "0",
              paddingRight: "0",
              paddingTop: "1rem",
              paddingBottom: "0",
              // boxShadow: "0 0 5px 10px #494949",
              boxShadow: "0 0 5px 10px rgba(73, 73, 73, .5)",
              // backdropFilter: "blur(30px)",
              zIndex: "100",
            }}
            onMouseEnter={() => {
              setCardEntered(true);
            }}
            onMouseLeave={() => {
              setCardEntered(false);
            }}
          >
            <SectionHeader
              section={section}
              collection={collection}
              workspaceId={workspaceId}
              spaceId={spaceId}
              supplierId={supplierId}
              setCancelView={setCancelView}
              cancelView={cancelView}
              isCardEntered={isCardEntered}
            />
          </Segment>

          <Card.Group
            centered
            itemsPerRow={1}
            style={{
              // borderRadius: "1rem",
              // backgroundColor: "gray",
              height: "calc(100vh - 2rem - calc(100vh - 100%))",
              overflowY: "auto",
            }}
          >
            <Container
              style={{
                width: "100%",
                marginTop: "1rem",
                marginBottom: "5rem",
                // paddingTop: "7rem",
                // height: "calc(100vh - 7rem)",
              }}
            >
              {isLoaded(cards) && !isEmpty(cards) ? (
                <>{childrenWithProps}</>
              ) : (
                // <></>
                <>'loading...'</>
              )}
            </Container>
          </Card.Group>
        </>
      )}
    </>
  );
}

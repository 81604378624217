import React from "react";
import { useField, useFormikContext } from "formik";
import { FormField, Label, Select, StepTitle } from "semantic-ui-react";

export default function MySelectProductInput({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const formikProps = useFormikContext();

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Select
        clearable
        value={field.value || null}
        onChange={(e, d) => {
          helpers.setValue(d.value);
          try {
            const valueToJson = JSON.parse(d.value);
            console.log("valueToJson", valueToJson);
            formikProps.setFieldValue("data", {
              productTitle: valueToJson.title,
              productId: valueToJson.id,
              imageResizedSmallUrl: valueToJson.imageResizedSmallUrl,
              imageResizedLargeUrl: valueToJson.imageResizedLargeUrl,
            });
          } catch (error) {
            console.log("error parsing the json value...");
          }
        }}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      />
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}

import React, { useState } from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Button, Container, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { deleteWorkspaceUser } from "../../app/firestore/firestoreService";

export default function UserConfirmModal({ user, workspaceId }) {
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  return (
    <ModalWrapper size="mini" header="Confirm removing user from the Workspace">
      <Grid>
        <Grid.Column textAlign="center">
          <Button onClick={() => dispatch(closeModal())} content="Cancel" />
          <Button
            loading={isSubmitting}
            onClick={() => {
              setSubmitting(true);
              dispatch(deleteWorkspaceUser(user, workspaceId))
                .then(() => {
                  setSubmitting(false);
                  dispatch(closeModal());
                  console.log("ok");
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }}
            content="Delete"
            negative
          />
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}

import React from "react";
import { Container, Grid, Table } from "semantic-ui-react";

import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

export default function SectionList({ children, sections }) {
  const ismobile = useMediaQuery({ query: "(max-width: 768px)" });
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { sections });
    }
    return child;
  });

  const tablelView = useSelector((state) => state.layout.value.tableView);

  return (
    <>
      {tablelView ? (
        <>
          <Container
            style={{
              backgroundColor: "grey",
              // height: "calc(100vh-1em)",
              height: "calc(100vh - 9rem)",
              width: "min-content",
              overflowY: "scroll",
              scrollSnapType: "y mandatory",
              display: "grid",
              // gridTemplateColumns: "repeat(3, 100%)",
            }}
          >
            {/* <Table celled striped> */}
            {childrenWithProps}
            {/* </Table> */}
          </Container>
        </>
      ) : (
        <Grid
          // columns={3}
          divided
          style={{
            //   backgroundColor: "gray",
            position: "fixed",
            height: "100%",
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            display: "grid",
            gridColumnGap: "1rem",
            margin: "0 0% 0 1%",
            left: "0",
            right: "0",

            gridTemplateColumns: `repeat(${sections.length}, ${
              ismobile ? "100%" : sections.length > 1 ? "25rem" : "100%"
            })`,
          }}
        >
          <>{childrenWithProps}</>
        </Grid>
      )}
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const roomSlice = createSlice({
  name: "room",
  initialState: {
    value: {
      wall_finishes: null,
      floor_finishes: null,
      ceiling_finishes: null,
      skirting_finishes: null,
    },
  },
  reducers: {
    setWall_finishes: (state, action) => {
      state.value.wall_finishes = action.payload;
    },
    setFloor_finishes: (state, action) => {
      state.value.floor_finishes = action.payload;
    },
    setCeiling_finishes: (state, action) => {
      state.value.ceiling_finishes = action.payload;
    },
    setSkirting_finishes: (state, action) => {
      state.value.skirting_finishes = action.payload;
    },
  },
});

export const {
  setWall_finishes,
  setFloor_finishes,
  setCeiling_finishes,
  setSkirting_finishes,
} = roomSlice.actions;
export default roomSlice.reducer;

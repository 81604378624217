import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";

import { Container } from "semantic-ui-react";

export default function SectionDashboard(props) {
  const { spaceId, supplierId, workspaceId } = useParams();

  //use workspaceId instead of spaceId in query if this dashboard was running at workspace level for library as example.

  const auth = useSelector((state) => state.firebase.auth);
  let cardQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    cardQuery = {
      //   collection: props.collection,
      collection: "sections",
      orderBy: ["createdAt", "asc"],
      storeAs: `sections/${props.sectionId}/${workspaceId}/${
        spaceId || supplierId
      }`,
      where: [
        spaceId ? ["spaceId", "==", spaceId || null] : null,
        supplierId ? ["supplierId", "==", supplierId || null] : null,
        ["workspaceId", "==", workspaceId],
        ["sectionId", "==", props.sectionId],
        ["isCancelled", "==", props.cancelView],
      ].filter(Boolean),
    };
  }

  useFirestoreConnect(() => [cardQuery]);

  const sections = useSelector(
    (state) =>
      state.firestore.ordered[
        `sections/${props.sectionId}/${workspaceId}/${spaceId || supplierId}`
      ]
  );

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { sections });
    }
    return child;
  });

  return (
    <>
      <Container
        style={{
          marginTop: props.sectionId === "selection3ds" ? "0" : "7rem",
          width: "100%",
          //   paddingTop: "7rem",
          // height: "calc(100vh - 7rem)",
        }}
      >
        {isLoaded(sections) && !isEmpty(sections) ? (
          <>{childrenWithProps}</>
        ) : (
          // <></>
          <>'loading...'</>
        )}
      </Container>
    </>
  );
}

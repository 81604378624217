import React from "react";
import { Container } from "semantic-ui-react";
import SelectionListItem from "./SelectionListItem";

export default function SelectionList({ cards, sectionId }) {
  return (
    <Container>
      {cards.map((card) => (
        <SelectionListItem card={card} key={card.id} sectionId={sectionId} />
      ))}
    </Container>
  );
}

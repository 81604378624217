import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { cancelDocById } from "../../app/firestore/firestoreService";

export default function SectionEditDropdown({ section, collection }) {
  const dispatch = useDispatch();
  return (
    <Dropdown
      direction='left'
      className='right floated'
      //   style={{ color: "white" }}
      icon='ellipsis vertical'
    >
      <Dropdown.Menu>
        {section.isCancelled ? (
          <>
            <Dropdown.Item
              // as={Link}
              // to={`/`}
              text='Restore'
              icon='unhide'
              onClick={() => {
                dispatch(cancelDocById(section.id, "sections", false));
              }}
            />
          </>
        ) : (
          <Dropdown.Item
            // as={Link}
            // to={`/`}
            text='Cancel'
            icon='hide'
            onClick={() => {
              dispatch(cancelDocById(section.id, "sections", true));
            }}
          />
        )}

        <Dropdown.Item
          text='Edit'
          icon='settings'
          onClick={() => {
            dispatch(
              openModal({
                modalType: "SectionFormModal",
                modalProps: {
                  workspaceId: section.workspaceId,
                  spaceId: section.spaceId,
                  selectedDoc: section,
                  collection: collection,
                },
              })
            );
          }}
        />
        <Dropdown.Item
          text='Id'
          icon='copy outline'
          onClick={() => {
            navigator.clipboard.writeText(section.id);
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Container, Grid, Header, Menu } from "semantic-ui-react";
import UserForm from "./UserForm";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import UserList from "./UserList";
import { openModal } from "../../app/common/modals/modalReducer";
import SubNavBar from "../nav/SubNavBar";

export default function UserDashboard() {
  const { workspaceId } = useParams();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.firebase.auth);
  const selectedWorkspace = useSelector(
    (state) => state.firestore.ordered[`workspaces/${workspaceId}`]
  );
  const users = useSelector((state) => state.users.workspace);

  if (isLoaded(selectedWorkspace) && !isEmpty(selectedWorkspace)) {
    const adminId = selectedWorkspace[0].createdBy;
    return (
      <>
        <SubNavBar>
          {auth.uid === adminId ? (
            <>
              <Menu.Item>
                <Button
                  circular
                  icon="plus"
                  inverted
                  color="orange"
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalType: "UserFormModal",
                        modalProps: {
                          workspaceId,
                        },
                      })
                    );
                  }}
                />
              </Menu.Item>
            </>
          ) : null}

          <Menu.Item>
            <Header
              style={{ color: "#28292B" }}
              id="fonts"
              inverted
              content="Users"
            />
          </Menu.Item>
        </SubNavBar>
        <Container style={{ marginTop: 100, minHeight: "100vh" }}>
          <Card.Group>
            <UserList
              workspaceId={workspaceId}
              auth={auth}
              users={users}
              adminId={adminId}
            />
          </Card.Group>
        </Container>
      </>
    );
  } else {
    return <Header content="Loading..." />;
  }
}

import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";

import { Environment, OrbitControls, useGLTF } from "@react-three/drei";

function Model({ url }) {
  const { scene } = useGLTF(url);
  return <primitive object={scene} />;
}

export default function GeometryBasicViewer({ card }) {
  return (
    <Canvas
      camera={{ position: [3, 1.75, -1] }}
      style={{ width: "100%", height: "600px", margin: "0" }}
    >
      {/* <color attach="background" args={["#111"]} /> */}
      <Suspense fallback={null}>
        {card.glbUrl && <Model url={card.glbUrl} />}
      </Suspense>
      <Environment
        files="/hdri/kloofendal_misty_morning_puresky_1k.hdr"
        ground={{
          height: 3,
          radius: 115,
          scale: 100,
        }}
      />
      <OrbitControls autoRotate />
    </Canvas>
  );
}

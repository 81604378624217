import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { categorySupplierProducts } from "../../../app/api/categoryOptions";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { addCard } from "../../../app/firestore/firestoreService";
import MySelectProductInput from "../../../app/common/form/MySelectProductInput";

const initialValues = {
  title: "",
  description: "",
  data: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("you must provide a title"),
  description: Yup.string().required("you must provide a description"),
  data: Yup.object().required("you must pass the data"),
});

export default function ProductSelectorForm({
  spaceId,
  supplierId,
  section,
  workspaceId,
  selectedDoc,
  collection,
  productId,
  SetProductCategory,
}) {
  const dispatch = useDispatch();
  const products = useSelector(
    (state) => state.firestore.ordered[`products/${workspaceId}`]
  );

  const productsOptions = products?.map((item) => ({
    key: item.id,
    text: item.title,
    value: JSON.stringify({ ...item }),
    // value: item.id,
    image: { avatar: false, src: item.imageResizedSmallUrl },
  }));
  if (products && productsOptions) {
    return (
      <Segment clearing basic>
        <Header sub content="Select" />
        <Header content={productId} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            console.log("values", values);
            const { data, product, category, ...valuesWithNoData } = values;
            const valuesWithData = { ...valuesWithNoData, ...values.data };
            try {
              await dispatch(
                addCard(
                  valuesWithData,
                  collection,
                  spaceId,
                  supplierId,
                  workspaceId,
                  section.id
                )
              );
              dispatch(closeModal());
              setSubmitting(false);
            } catch (error) {
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <MyTextInput name="title" placeholder="Title..." />
              <MyTextInput hidden name="data" placeholder="passed data..." />
              <MyTextArea
                name="description"
                placeholder="Description..."
                rows={3}
              />
              {/* <MySelectInput
                name='category'
                placeholder='category...'
                options={categorySupplierProducts}
                setselectvalue={SetProductCategory}
              /> */}
              <MySelectProductInput
                name="product"
                placeholder="Product..."
                options={productsOptions}
              />
              {errors.submit && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.submit}
                />
              )}
              <Button
                style={{ backgroundColor: "#ffa600", color: "white" }}
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                floated="right"
                positive
                content="Create"
              />
            </Form>
          )}
        </Formik>
      </Segment>
    );
  } else {
    return <></>;
  }
}

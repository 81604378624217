import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { addWorkspaceUser } from "../../app/firestore/firestoreService";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";

const initialValues = {
  id: "",
};

const validationSchema = Yup.object({
  email: Yup.string().required("you must provide an email"),
});

export default function UserForm({ workspaceId }) {
  const dispatch = useDispatch();
  return (
    <Segment clearing>
      <Header sub color='teal' content='Add User Email' />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          dispatch(addWorkspaceUser(values.email, workspaceId))
            .then(() => {
              dispatch(closeModal());
              setSubmitting(false);
            })
            .catch((error) => {
              setErrors({ submit: error.message });
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='email' placeholder='Enter email...' />

            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              positive
              content='Submit'
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}

import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CommitFileForm from "./CommitFileForm";

export default function CommitFileFormModal({ data, field, collection }) {
  // the OR || data.supplierId condition is added to allow the commit file to work for supplier otherwise undefined will be returned and upload will not work.
  console.log(
    "modal: ",
    data.id,
    data.spaceId || data.supplierId,
    data.workspaceId
  );
  return (
    <ModalWrapper size="small">
      <CommitFileForm
        docId={data.id}
        spaceId={data.spaceId || data.supplierId}
        workspaceId={data.workspaceId}
        field={field}
        collection={collection}
      />
    </ModalWrapper>
  );
}

import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import { Button, FormField, Header, Input, Label } from "semantic-ui-react";
import { readFile } from "../util/readFile";

export default function MyImageInput({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const formikProps = useFormikContext();
  const { setImage, ...propsWithout } = props;
  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Button as='label' htmlFor='file' type='button'>
        {field.value ? field.value.name : props.placeholder}
      </Button>

      <input
        {...propsWithout}
        hidden
        onChange={async (event) => {
          formikProps.setFieldValue(props.name, event.currentTarget.files[0]);
          props.setImage(null);
          if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            let imageDataUrl = await readFile(file);
            props.setImage(imageDataUrl);
          }
        }}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}

import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Header,
  Image,
  Label,
  Progress,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addComment } from "../../app/firestore/firestoreService";
import MyTextArea from "../../app/common/form/MyTextArea";
import { uploadToFirebaseStorage } from "../../app/firestore/firebaseService";
import MyImageInput from "../../app/common/form/MyImageInput";

const initialValues = {
  comment: "",
  file: undefined,
};

const validationSchema = Yup.object({
  comment: Yup.string().notRequired(),
  file: Yup.mixed().notRequired(),
});

export default function CommentForm({ docId, workspaceId, collectionFolder }) {
  const [image, setImage] = useState(undefined);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  function isPromise(obj) {
    return (
      !!obj &&
      (typeof obj === "object" || typeof obj === "function") &&
      typeof obj.then === "function"
    );
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          const { file, ...valuesWithNoFile } = values;
          try {
            const childId = await dispatch(addComment(valuesWithNoFile, docId));
            if (values.file) {
              await dispatch(
                uploadToFirebaseStorage(
                  values.file,
                  values.file.name,
                  docId,
                  childId,
                  collectionFolder,
                  "comments",
                  workspaceId,
                  false,
                  setProgress
                )
              );
            }

            resetForm();
            setImage(undefined);
            setSubmitting(false);
            setProgress(0);
          } catch (error) {
            setErrors({ submit: error.message });
            setSubmitting(false);
            setProgress(0);
          }
        }}
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          errors,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form className='ui form'>
            <MyTextArea
              isSubmitting={isSubmitting}
              name='comment'
              placeholder='Type your comment here...'
              rows={2}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                  return;
                }
                if (e.key === "Enter" && !e.shiftKey) {
                  handleSubmit();
                }
              }}
            />
            <MyImageInput
              id='file'
              type='file'
              accept='image/*'
              name='file'
              placeholder='browse image'
              setImage={setImage}
            />

            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
          </Form>
        )}
      </Formik>
      {progress > 0 && (
        <Progress percent={progress} size='tiny' color='orange' />
      )}
      {image && <Image src={image} style={{ height: "200px" }} />}
    </>
  );
}

const fbConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "mattello-4cb7b.firebaseapp.com",
  projectId: "mattello-4cb7b",
  storageBucket: "mattello-4cb7b.appspot.com",
  messagingSenderId: "870960266301",
  appId: "1:870960266301:web:57260d928309155b97be54",
  measurementId: "G-113R539GET",
}; // your firebase config

export default fbConfig;

import { format, formatDistance } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { saveAs } from "file-saver";
import {
  cancelDocById,
  deleteDocFromFirestore,
} from "../../app/firestore/firestoreService";
import ItemEditDropdown from "../item/ItemEditDropdown";
import ProfilePopup from "../profiles/ProfilePopup";

export default function CardListItem({
  card,
  collection,
  children,
  direction,
  extra = false,
  compact = false,
  sectionId,
}) {
  let descExtra = extra ? 200 : 80;

  const dispatch = useDispatch();
  const [isCardEntered, setCardEntered] = useState(false);
  //   const [isButtonEntered, setButtonEntered] = useState(false);

  const users = useSelector((state) => state.users.workspace);
  const user = users ? users.find((item) => item.id === card.createdBy) : null;

  const productCode =
    card.sections && card.sections.filter((obj) => obj.id === sectionId);

  return (
    <>
      <Card
        className={direction}
        fluid
        onMouseEnter={() => {
          setCardEntered(true);
        }}
        onMouseLeave={() => {
          setCardEntered(false);
        }}
      >
        {direction === "vertical" && (
          <>
            {card.imageResizedSmallUrl ? (
              <div className="img-ratio">
                <Image
                  size="medium"
                  src={card.imageResizedSmallUrl}
                  //   wrapped
                  ui={false}
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalType: "CardModal",
                        modalProps: {
                          data: card,
                          collectionFolder: collection,
                        },
                      })
                    );
                  }}
                />
              </div>
            ) : compact ? (
              <></>
            ) : (
              <div className="img-ratio">
                <Image />
              </div>
            )}
          </>
        )}

        <Card.Content style={{ overflow: "hidden" }}>
          <Card.Header
            className="left floated"
            content={`${
              extra && productCode
                ? productCode[0].type + "_" + productCode[0].codeId + " : "
                : ""
            } ${card.title}`}
          />

          {isCardEntered ? (
            <ItemEditDropdown card={card} collection={collection} />
          ) : null}
          {extra && <Card.Description content={card.type} />}

          <Card.Description
            content={`${card.description.substring(0, descExtra)}${
              card.description.length > descExtra ? "..." : ""
            }`}
          />
          {children[1]}
          <Divider />

          <Card.Meta className="left floated">
            {collection === "selection3ds" ? null : (
              <List horizontal>
                <List.Item>
                  <ProfilePopup user={user}>
                    <Image
                      size="huge"
                      //   floated='right'
                      avatar
                      // style={{ top: "calc(100% - 2em)", border: "1px solid white" }}
                      style={{ border: "2px solid orange" }}
                      // spaced='right'
                      src={user?.photoURL || "/assets/user.png"}
                    />
                  </ProfilePopup>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {card.createdAt && (
                      <div>
                        {format(card.createdAt.toDate(), "MMMM d, yyyy")}
                      </div>
                    )}
                  </List.Content>
                </List.Item>
              </List>
            )}
          </Card.Meta>
          <Segment basic floated="right" style={{ margin: "0", padding: "0" }}>
            <div style={{ display: "inline-flex", gap: "0.5rem" }}>
              {children[0] || children}
            </div>
          </Segment>
        </Card.Content>
        {card.imageResizedSmallUrl ? (
          direction === "horizontal" && (
            <Image
              size="medium"
              src={card.imageResizedSmallUrl}
              wrapped
              ui={false}
            />
          )
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

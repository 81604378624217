import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import {
  Button,
  Card,
  Comment,
  Container,
  Grid,
  Header,
  Item,
  Segment,
} from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function ProductDetails({ docId }) {
  const dispatch = useDispatch();
  let selectedProduct;
  const auth = useSelector((state) => state.firebase.auth);

  let SelectedProductQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    SelectedProductQuery = {
      collection: "products",
      doc: docId,
      storeAs: `products/${docId}`,
    };
  }
  useFirestoreConnect(() => [SelectedProductQuery]);
  selectedProduct = useSelector(
    (state) => state.firestore.ordered[`products/${docId}`]
  );
  return (
    <>
      {isLoaded(selectedProduct) && !isEmpty(selectedProduct) ? (
        <Grid columns={4} className='very compact centered'>
          <Grid.Row>
            <Button
              content={`Product Page: ${selectedProduct[0].title}`}
              onClick={() => {
                dispatch(
                  openModal({
                    modalType: "CardModal",
                    modalProps: {
                      data: selectedProduct[0],
                      collectionFolder: "products",
                    },
                  })
                );
              }}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h5'>
                Material
                <Header.Subheader>
                  {selectedProduct[0].material}
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Finish
                <Header.Subheader>{selectedProduct[0].finish}</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Color
                <Header.Subheader>{selectedProduct[0].color}</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Brand
                <Header.Subheader>{selectedProduct[0].brand}</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Collection
                <Header.Subheader>
                  {selectedProduct[0].collection}
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Size
                <Header.Subheader>{selectedProduct[0].size}</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Price Supply
                <Header.Subheader>
                  {selectedProduct[0].pricesupply}
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                Price Installation
                <Header.Subheader>
                  {selectedProduct[0].priceinstallation}
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <>'loading...'</>
      )}
    </>
  );
}

import React from "react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import SupplierForm from "./SupplierForm";

export default function SupplierFormModal({
  workspaceId,
  spaceId,
  section,
  selectedDoc,
  collection,
}) {
  return (
    <ModalWrapper size='small'>
      <SupplierForm
        workspaceId={workspaceId}
        spaceId={spaceId}
        sectionId={section?.id}
        selectedDoc={selectedDoc}
        collection={collection}
      />
    </ModalWrapper>
  );
}

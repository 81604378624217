import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container, Grid, Header, Menu } from "semantic-ui-react";
import WorkspaceForm from "./WorkspaceForm";
import WorkspaceList from "./WorkspaceList";
import WorkspaceListItemPlaceholder from "./WorkspaceListItemPlaceholder";
import {
  useFirestoreConnect,
  useFirebase,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import SubNavBar from "../nav/SubNavBar";
import { openModal } from "../../app/common/modals/modalReducer";

export default function WorkspaceDashboard() {
  // const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();
  let workspacesQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    workspacesQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
      where: [
        ["membersIds", "array-contains", auth.uid],
        ["isCancelled", "==", false],
      ],
    };
  } else {
    workspacesQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
    };
  }

  useFirestoreConnect(() => [workspacesQuery]);

  const { workspaces } = useSelector((state) => state.firestore.ordered);

  return (
    <>
      <SubNavBar>
        <Menu.Item>
          <Button
            circular
            inverted
            color='orange'
            icon='plus'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "WorkspaceFormModal",
                  modalProps: {},
                })
              );
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Header
            style={{ color: "#28292B" }}
            id='fonts'
            inverted
            content='Workspaces'
          />
        </Menu.Item>
      </SubNavBar>
      <Container
        style={{
          marginTop: "8rem",
        }}
      >
        {isLoaded(workspaces) && !isEmpty(workspaces) ? (
          <WorkspaceList workspaces={workspaces} />
        ) : (
          <>
            <Card.Group stackable itemsPerRow={4}>
              <WorkspaceListItemPlaceholder />
              <WorkspaceListItemPlaceholder />
            </Card.Group>
          </>
        )}
      </Container>
    </>
  );
}

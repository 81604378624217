import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardListItem from "../../card/CardListItem";

export default function SupplierListItem({ card }) {
  const dispatch = useDispatch();

  return (
    <CardListItem card={card} collection='suppliers' direction='vertical'>
      <>
        <Button
          floated='right'
          icon='box'
          as={NavLink}
          to={`/workspaces/${card.workspaceId}/suppliers/${card.id}/`}
        />
        <Button
          floated='right'
          icon='file alternate'
          onClick={() => {
            dispatch(
              openModal({
                modalType: "CommentModal",
                modalProps: { data: card, collectionDB: "suppliers" },
              })
            );
          }}
        />
      </>

      {/* <Header content='hello world' /> */}
      {/* you can add here second
      children */}
    </CardListItem>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import { Button, Header } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardListItem from "../../card/CardListItem";

export default function SelectionListItem({ card, sectionId }) {
  const dispatch = useDispatch();

  return (
    <CardListItem card={card} collection="selections" direction="vertical">
      <>
        <Button
          floated="right"
          icon="file alternate"
          onClick={() => {
            dispatch(
              openModal({
                modalType: "CommentModal",
                modalProps: { data: card, collectionDB: "selections" },
              })
            );
          }}
        />
        <Button
          size="tiny"
          compact
          icon="desktop"
          onClick={() => {
            dispatch(
              openModal({
                modalType: "GeometryBasicViewerModal",
                modalProps: { data: card, collectionDB: "selections" },
              })
            );
          }}
        />
      </>
    </CardListItem>
  );
}

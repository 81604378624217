import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Header, Menu } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { switchView } from "../../../app/layout/layoutSlice";
import CardColumn from "../../card/CardColumn";

import SubNavBar from "../../nav/SubNavBar";
import SectionDashboard from "../../section/SectionDashboard";
import SectionList from "../../section/SectionList";
import { setEditRowId } from "../../table/tableSlice";
import RoomList from "./RoomList";
import {
  setCeiling_finishes,
  setFloor_finishes,
  setSkirting_finishes,
  setWall_finishes,
} from "./roomSlice";

function useProductSelection(category) {
  const { spaceId, workspaceId } = useParams();
  const spaceData = useSelector(
    (state) => state.firestore.ordered[`spaces/${spaceId}`]
  );
  const sectionId = spaceData ? spaceData[0][category] : null;
  const query = {
    collection: "selections",
    orderBy: ["createdAt", "asc"],
    storeAs: `roomSelections/${spaceId}/${sectionId}`,
    where: [
      ["workspaceId", "==", workspaceId],
      ["sectionId", "==", sectionId || null],
      ["isCancelled", "==", false],
    ],
  };
  useFirestoreConnect(() => [query]);

  const selections = useSelector(
    (state) => state.firestore.ordered[`roomSelections/${spaceId}/${sectionId}`]
  );

  const selectionsOptions = selections?.map((item) => ({
    key: item.id,
    label: item.title,
    value: JSON.stringify({ ...item }),
    // value: item.id,
    image: { avatar: false, src: item.imageResizedSmallUrl },
  }));

  return selectionsOptions;
}

export default function RoomDashboard() {
  const { spaceId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const [cancelView, setCancelView] = useState(false);

  const tablelView = useSelector((state) => state.layout.value.tableView);

  dispatch(setWall_finishes(useProductSelection("rds_wall_finishes")));
  dispatch(setFloor_finishes(useProductSelection("rds_floor_finishes")));
  dispatch(setCeiling_finishes(useProductSelection("rds_ceiling_finishes")));
  dispatch(setSkirting_finishes(useProductSelection("rds_skirting_finishes")));

  useEffect(() => {
    dispatch(switchView(true));

    return () => {
      dispatch(setEditRowId(null));
      dispatch(switchView(false));
    };
  }, []);

  return (
    <>
      <SubNavBar>
        <Menu.Item>
          <Button
            icon='plus'
            inverted
            color='orange'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: `SectionFormModal`,
                  modalProps: {
                    workspaceId,
                    spaceId,
                    selectedDoc: null,
                    collection: "rooms",
                  },
                })
              );
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Header
            style={{ color: "#28292B" }}
            id='fonts'
            inverted
            content={"Rooms"}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            toggle
            onChange={() => {
              setCancelView(!cancelView);
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Button
            basic
            toggle
            icon={tablelView ? "list" : "columns"}
            onClick={() => {
              dispatch(switchView(!tablelView));
              dispatch(setEditRowId(null));
            }}
          />
        </Menu.Item>
      </SubNavBar>
      <SectionDashboard cancelView={cancelView} sectionId='rooms'>
        <SectionList>
          <CardColumn collection={"rooms"}>
            <RoomList />
          </CardColumn>
        </SectionList>
      </SectionDashboard>
    </>
  );
}

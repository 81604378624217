import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import { Container, Grid, Header, Image } from "semantic-ui-react";

export default function ProfilePage() {
  const { profileId } = useParams();

  const SelectedProfileQuery = {
    collection: "users",
    doc: profileId || null,
    storeAs: `users/${profileId}`,
  };

  useFirestoreConnect(() => [SelectedProfileQuery]);

  const selectedUser = useSelector(
    (state) => state.firestore.ordered[`users/${profileId}`]
  );

  console.log(selectedUser && selectedUser[0]);

  if (isLoaded(selectedUser) && !isEmpty(selectedUser)) {
    return (
      <>
        <Container style={{ marginTop: 90, minHeight: "100vh" }}>
          <Grid centered textAlign='center' columns={1}>
            <Grid.Row>
              <Header color='orange' inverted size='huge' content={"Profile"} />
            </Grid.Row>
            <Grid.Row>
              <Image
                centered
                avatar
                size='medium'
                src={selectedUser[0].photoURL || "/assets/user.png"}
              />
            </Grid.Row>

            <Grid.Row>
              <Header
                inverted
                size='huge'
                content={selectedUser[0].displayName}
              />
            </Grid.Row>
            <Grid.Row>
              <Header
                color='grey'
                size='medium'
                content={selectedUser[0].position}
              />
            </Grid.Row>
            <Grid.Row>
              <Header
                color='grey'
                size='tiny'
                content={selectedUser[0].email}
              />
            </Grid.Row>
            <Grid.Row>
              <Header
                color='grey'
                size='tiny'
                content={`Member since: ${format(
                  selectedUser[0].createdAt.toDate(),
                  "MMMM d, yyyy"
                )}`}
              />
            </Grid.Row>
          </Grid>
        </Container>
      </>
    );
  } else {
    return <Header content='Loading...' />;
  }
}

import React from "react";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Grid, Header } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import SectionEditDropdown from "../item/SectionEditDropdown";

export default function SectionHeader({
  section,
  collection,
  workspaceId,
  spaceId,
  supplierId,
  setCancelView,
  cancelView,
  isCardEntered,
}) {
  const dispatch = useDispatch();
  console.log("section...id", section.id);
  return (
    <Grid columns="equal" container>
      {isCardEntered && (
        <Grid.Column>
          <Button
            circular
            icon="plus"
            inverted
            size="tiny"
            color="orange"
            onClick={() => {
              dispatch(
                openModal({
                  modalType: `${
                    collection === "suppliers"
                      ? "Supplier"
                      : collection === "products"
                      ? "Product"
                      : collection === "selections"
                      ? "ProductSelector"
                      : collection === "selection3ds"
                      ? "Selection3d"
                      : "Card"
                  }FormModal`,
                  modalProps: {
                    workspaceId,
                    spaceId: spaceId || null,
                    supplierId: supplierId || null,
                    section: section,
                    selectedDoc: null,
                    collection: collection,
                  },
                })
              );
            }}
          />
        </Grid.Column>
      )}

      <Grid.Column width={9}>
        <Header
          textAlign="center"
          content={section.title}
          style={{ color: "#e2e2e2" }}
        />
      </Grid.Column>
      {isCardEntered && (
        <>
          <Grid.Column>
            <Checkbox
              toggle
              onChange={() => {
                setCancelView(!cancelView);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <SectionEditDropdown section={section} collection={collection} />
          </Grid.Column>
        </>
      )}
    </Grid>
  );
}

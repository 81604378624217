import { DragControls, Html, useGLTF } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";

import { useDispatch, useSelector } from "react-redux";
import {
  filterShapesOnCanvas,
  setActionHistory,
  setCamControl,
  setSelectedObj,
  setShapeMeshId,
  setShapePosition,
  setShapesOnCanvas,
} from "./threeSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Icon } from "semantic-ui-react";
import { debounce } from "lodash";
import * as THREE from "three";
import { useSpring, animated } from "@react-spring/three";

export default function ExternalModel({ card, cameraControlsRef, index, canvasScene }) {
  const { scene } = useGLTF(card.glbUrl);
  console.log("url......check", card.glbUrl);
  const dispatch = useDispatch();
  const modelRef = useRef();
  const dragRef = useRef();

  const selectedObj = useSelector((state) => state.three.selectedObj);
  const editMode = useSelector((state) => state.three.editMode);

  const [hovered, hover] = useState(null);

  // Debounce hover a bit to stop the ticker from being erratic
  const debouncedHover = useCallback(debounce(hover, 30), []);
  const over = (name) => (e) => (e.stopPropagation(), debouncedHover(name));

  function setObjTransformation(modelRef) {
    let vector = new THREE.Vector3();
    let vectorRot = new THREE.Quaternion();

    modelRef.current.getWorldPosition(vector);
    modelRef.current.getWorldQuaternion(vectorRot);
    const position = [vector.x, vector.y, vector.z];
    const rotation = [vectorRot.x, vectorRot.y, vectorRot.z, vectorRot.w];
    const id = modelRef.current.id;

    // console.log(modelRef.current);

    dispatch(setShapePosition({ index, position, rotation }));
    dispatch(setActionHistory({ index, id, position, rotation }));
  }

  useEffect(() => {
    const baseVector = new THREE.Vector3(-4, 0, 0);
    const currentVector = new THREE.Vector3(
      card?.position ? card.position[0] : 0,
      card?.position ? card.position[1] : 0,
      card?.position ? card.position[2] : 0
    );

    const baseQuaternion = new THREE.Quaternion(0, 0, 0, 0);
    const currentQuaternion = new THREE.Quaternion(
      card?.rotation ? card.rotation[0] : 0,
      card?.rotation ? card.rotation[1] : 0,
      card?.rotation ? card.rotation[2] : 0,
      card?.rotation ? card.rotation[3] : 0
    );

    // console.log("card?.position", card?.position, currentVector);

    // console.log("first ...", modelRef?.current);
    modelRef?.current?.position.copy(card?.position ? currentVector : baseVector);
    modelRef?.current?.quaternion.copy(card?.rotation ? currentQuaternion : baseQuaternion);
  }, []);

  const { modelPosition, modelScale } = useSpring({
    from: {
      modelPosition: [0, 1, 0],
      modelScale: 0,
    },
    to: [
      {
        modelPosition: [0, 0, 0],
        modelScale: 1,
        delay: 60 * index,
      },
    ],
    config: {
      mass: 2,
      tension: 400,
      friction: 40,
    },
    loop: false,
  });

  if (card.isDeleted === false) {
    return (
      <Select
        enabled={hovered === modelRef?.current?.uuid && editMode}
        onPointerOver={over(modelRef?.current?.uuid)}
        onPointerOut={() => debouncedHover(null)}
        onPointerUp={() => {
          const uuid = modelRef?.current?.uuid;
          setObjTransformation(modelRef);
          dispatch(setShapeMeshId({ index, uuid }));
          // console.log("current model", modelRef.current);
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
          setObjTransformation(modelRef);
          dispatch(setSelectedObj(modelRef?.current?.uuid));
          !editMode && cameraControlsRef.current?.fitToBox(modelRef?.current, true);

          // console.log(card?.position);
          // console.log("dragRef", dragRef.current);
        }}
        onPointerMissed={() => {
          // console.log("missed form sofa");
        }}
      >
        <DragControls
          ref={dragRef}
          dragConfig={{ enabled: editMode }}
          axisLock="y"
          onDragStart={() => {
            dispatch(setCamControl(false));
          }}
          onDragEnd={() => {
            dispatch(setCamControl(true));
          }}
        >
          <animated.group position={modelPosition || [0, 0, 0]}>
            <mesh
              // onBeforeRender={console.log("not yet rendered")}
              ref={modelRef}
            >
              <primitive object={scene.clone()} />
              {selectedObj === modelRef?.current?.uuid && (
                <Html sprite zIndexRange={[2, 0]}>
                  <Card>
                    {!editMode && (
                      <Card.Content>
                        <Card.Header>{card.title}</Card.Header>
                        <Card.Meta>
                          <span className="date">Price : €{card.pricesupply}</span>
                        </Card.Meta>
                        <Card.Description>{card.description}</Card.Description>
                        <Icon name="linkify" />
                        <a href={card?.productUrl} target="_blank" rel="noreferrer">
                          View product page
                        </a>
                      </Card.Content>
                    )}

                    {editMode && (
                      <Card.Content extra>
                        <Button
                          icon="undo"
                          onClick={(e) => {
                            e.stopPropagation();

                            modelRef.current.rotation.y += (45 * Math.PI) / 180;
                            setObjTransformation(modelRef);
                          }}
                        />
                        <Button
                          icon="redo"
                          onClick={(e) => {
                            e.stopPropagation();
                            modelRef.current.rotation.y -= (45 * Math.PI) / 180;
                            setObjTransformation(modelRef);
                          }}
                        />
                        <Button
                          icon="copy outline"
                          onClick={() => {
                            const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
                            const xSize = boundingBox.max.x - boundingBox.min.x;
                            const ySize = boundingBox.max.y - boundingBox.min.y;
                            const zSize = boundingBox.max.z - boundingBox.min.z;

                            const maxSize = Math.max(xSize, ySize, zSize);
                            // console.log("Volume:", maxSize);

                            const newPos = {
                              position: [
                                card?.position[0] + xSize + 0.1,
                                card?.position[1],
                                card?.position[2],
                              ],
                            };
                            const updatedCard = { ...card, ...newPos };

                            dispatch(setShapesOnCanvas(updatedCard));
                          }}
                        />

                        <Button
                          icon="trash"
                          floated="right"
                          onClick={() => {
                            // console.log("reffff", modelRef);
                            // console.log("card id", card.id);
                            // console.log("userData", modelRef.current.userData);

                            modelRef.current.userData = { index: index };

                            dispatch(filterShapesOnCanvas(index));

                            dispatch(setSelectedObj(null));
                          }}
                        />
                      </Card.Content>
                    )}
                  </Card>
                </Html>
              )}
            </mesh>
          </animated.group>
        </DragControls>
      </Select>
    );
  } else {
    return;
  }
}

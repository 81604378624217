import {
  format,
  fromUnixTime,
  differenceInDays,
  intervalToDuration,
  formatDuration,
} from "date-fns";

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function filterPlainArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    return filterKeys.every((filter) => {
      // console.log("ff", filters[filter]);
      // const arrayKeys = Object.keys(item);
      // console.log("xx", item[filter]);
      let inputItem = item[filter];
      if (item[filter].seconds) {
        inputItem = format(fromUnixTime(item[filter].seconds), "yyyyMMdd");
      }
      // includes better than '=='
      return inputItem.toLowerCase().includes(filters[filter]);
    });
  });
}

export function filterDateArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    return filterKeys.every((filter) => {
      // console.log('ff',filter,filters[filter])
      // const arrayKeys = Object.keys(item);
      console.log("xxx", item[filter]);
      if (item[filter].seconds) {
        console.log("it is a date", item[filter].seconds);
      } else {
        console.log("it is not a date", item[filter]);
      }

      return (
        format(fromUnixTime(item[filter].seconds), "yyyyMMdd") ===
        filters[filter]
      );
    });
  });
}

export function getDifferenceArrayObjects(array1, array2, attr) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return eval("object1." + attr + " == object2." + attr);
    });
  });
}

export function getDaysAgo(days) {
  const todayDate = new Date();
  const previousDate = new Date();
  return new Date(
    previousDate.setTime(todayDate.getTime() - days * 24 * 3600000)
  );
}

export function createKeywords(name) {
  const arrName = [];
  let curName = "";
  name.split("").forEach((letter) => {
    curName += letter;
    arrName.push(curName);
  });
  return arrName;
}

export function dateDuration(start, end) {
  if (start & end) {
    return formatDuration(
      intervalToDuration({ start: end.toDate(), end: start.toDate() }),
      { format: ["years", "months", "weeks", "days"] }
    );
  } else if (start) {
    return formatDuration(
      intervalToDuration({ start: new Date(), end: start.toDate() }),
      { format: ["years", "months", "weeks", "days"] }
    );
  } else {
    return "--";
  }
}
export function getDate(date) {
  if (date) {
    return format(date.toDate(), "MMMM d, yyyy");
  } else {
    return "--";
  }
}

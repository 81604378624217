import React from "react";
import { Card, Container } from "semantic-ui-react";
import ProductMarketListItem from "./ProductMarketListItem";

export default function ProductMarketList({ cards }) {
  // console.log("cards", cards);
  return (
    <Container
      style={{
        // backgroundColor: "grey",
        // height: "calc(100vh-1em)",
        height: "calc(100vh - 9rem)",

        overflowY: "scroll",
        scrollSnapType: "y mandatory",
      }}
    >
      <Card.Group
        stackable
        itemsPerRow={2}
        style={{ paddingRight: "1px", paddingLeft: "1px" }}
      >
        {cards?.map((card) => (
          <ProductMarketListItem card={card} key={card.id} />
        ))}
      </Card.Group>
    </Container>
  );
}

import React from "react";
import { Header } from "semantic-ui-react";
import UserListItem from "./UserListItem";

export default function UserList({ workspaceId, auth, users, adminId }) {
  if (users) {
    return (
      <>
        {users.map((user) => (
          <UserListItem
            workspaceId={workspaceId}
            auth={auth}
            user={user}
            key={user.id}
            adminId={adminId}
          />
        ))}
      </>
    );
  } else {
    return (
      <>
        <Header content="loading..." />
      </>
    );
  }
}

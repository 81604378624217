import React from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";

export default function HomePage({ history }) {
  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container>
        <Header as="h1" size="huge" inverted content={`MATTELLO `} />
        <Header size="large" inverted content={`Build With Confidence `} />

        <Button onClick={() => history.push("/home")} size="huge" inverted>
          Start Now
        </Button>
      </Container>
    </Segment>
  );
}

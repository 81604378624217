import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Header, Label, Progress, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import MyTextArea from "../../../app/common/form/MyTextArea";
import {
  addCard,
  storageDeleteFolder,
  updateCard,
} from "../../../app/firestore/firestoreService";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import MyFileInput from "../../../app/common/form/MyFileInput";
import { uploadToFirebaseStorage } from "../../../app/firestore/firebaseService";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { categorySupplierProducts } from "../../../app/api/categoryOptions";

const initialValues = {
  title: "",
  description: "",
  type: "",
  material: "",
  finish: "",
  color: "",
  brand: "",
  collection: "",
  size: "",
  pricesupply: "",
  priceinstallation: "",
  productUrl: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("you must provide a title"),
  description: Yup.string().required("you must provide a description"),
  type: Yup.string().required("you must select a type"),
});

export default function ProductForm({
  spaceId,
  supplierId,
  sectionId,
  workspaceId,
  selectedDoc,
  collection,
}) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  return (
    <Segment basic clearing>
      <Header content={selectedDoc ? "Edit product info" : "Add a product"} />
      {progress > 0 && (
        <Progress percent={progress} size="tiny" color="orange" />
      )}
      <Formik
        enableReinitialize
        initialValues={selectedDoc ? selectedDoc : initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { file, ...valueWithNoFile } = values;
          try {
            if (selectedDoc) {
              await dispatch(
                updateCard(valueWithNoFile, collection, selectedDoc.id)
              );
              if (values.file) {
                function resizedName(fileName, dimensions) {
                  const extIndex = fileName.lastIndexOf(".");
                  return `${fileName.substring(
                    0,
                    extIndex
                  )}_${dimensions}${".jpeg"}`;
                }

                const imagePath = `workspaces/${workspaceId}/${collection}/${selectedDoc.id}/original`;

                if (selectedDoc.imageFilename) {
                  await dispatch(
                    storageDeleteFolder(
                      `${imagePath}/${selectedDoc.imageFilename}`
                    )
                  );
                  await dispatch(
                    storageDeleteFolder(
                      `${imagePath}/resized/${resizedName(
                        selectedDoc.imageFilename,
                        "1200x1200"
                      )}`
                    )
                  );
                  await dispatch(
                    storageDeleteFolder(
                      `${imagePath}/resized/${resizedName(
                        selectedDoc.imageFilename,
                        "300x300"
                      )}`
                    )
                  );
                }

                await dispatch(
                  uploadToFirebaseStorage(
                    values.file,
                    values.file.name,
                    selectedDoc.id,
                    null,
                    collection,
                    collection,
                    workspaceId,
                    false,
                    setProgress
                  )
                );
              }
              dispatch(closeModal());
              setSubmitting(false);
            } else {
              await dispatch(
                addCard(
                  valueWithNoFile,
                  collection,
                  spaceId,
                  supplierId,
                  workspaceId,
                  sectionId
                )
              );
              dispatch(closeModal());
              setSubmitting(false);
            }
          } catch (error) {
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, setFieldValue }) => (
          <Form className="ui form">
            <MyTextInput name="title" placeholder="Name" />

            <MyTextArea
              name="description"
              placeholder="Description..."
              rows={3}
            />
            <MySelectInput
              name="type"
              placeholder="type..."
              options={categorySupplierProducts}
            />
            <MyTextInput name="material" placeholder="Material" />
            <MyTextInput name="finish" placeholder="Finish" />
            <MyTextInput name="color" placeholder="Color" />
            <MyTextInput name="brand" placeholder="Brand" />
            <MyTextInput name="collection" placeholder="collection" />
            <MyTextInput
              name="size"
              placeholder="Size Width cm x Long cm x Thickness mm"
            />
            <MyTextInput name="pricesupply" placeholder="Price Supply" />
            <MyTextInput
              name="priceinstallation"
              placeholder="Price Installation"
            />
            <MyTextInput name="productUrl" placeholder="Product URL" />

            {errors.submit && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}

            <MyFileInput
              name="file"
              id="file"
              type="file"
              accept="image/*"
              placeholder="browse file"
            />

            <Button
              style={{ backgroundColor: "#ffa600", color: "white" }}
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              floated="right"
              content={selectedDoc ? "Edit" : "Add"}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}

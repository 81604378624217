import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: { value: { tableView: false, theme: "dark" } },
  reducers: {
    switchView: (state, action) => {
      state.value.tableView = action.payload;
    },
  },
});

export const { switchView } = layoutSlice.actions;
export default layoutSlice.reducer;

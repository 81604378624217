import { format, formatDistance } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { Container, Header, Icon, Table } from "semantic-ui-react";

export default function CommitList({ docId, workspaceId }) {
  const dispatch = useDispatch();

  let commits = [];
  const auth = useSelector((state) => state.firebase.auth);
  let commitsQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    commitsQuery = {
      collection: "commits",
      orderBy: ["createdAt", "desc"],
      storeAs: `commits/${docId}`,
      where: [["docId", "==", docId]],
    };
  }
  useFirestoreConnect(() => [commitsQuery]);
  commits = useSelector((state) => state.firestore.ordered[`commits/${docId}`]);

  const selectedWorkspace = useSelector(
    (state) =>
      workspaceId && state.firestore.ordered[`workspaces/${workspaceId}`]
  );

  function getMember(members, doc) {
    return members.filter((member) => member.id === doc.createdBy)[0];
  }
  return (
    <>
      {isLoaded(commits) && !isEmpty(commits) ? (
        <Container style={{ maxWidth: "100%" }}>
          <Table unstackable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='4'>All Commits</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {commits.map((commit) => (
                <Table.Row key={commit.id}>
                  <Table.Cell>
                    {selectedWorkspace &&
                      getMember(selectedWorkspace[0].members, commit)
                        .displayName}
                  </Table.Cell>
                  <Table.Cell>{commit.description}</Table.Cell>
                  <Table.Cell>{commit.fileType}</Table.Cell>
                  <Table.Cell collapsing textAlign='right'>
                    {commit.createdAt &&
                      format(commit.createdAt.toDate(), "MMM d, yyyy hh:mm a")}
                  </Table.Cell>
                </Table.Row>
                // <Header content={commit.description} key={commit.id} />
              ))}
            </Table.Body>
          </Table>
        </Container>
      ) : (
        <>'loading...'</>
      )}
    </>
  );
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: ChristyHsu (https://sketchfab.com/ida61xq)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/dining-room-kichen-baked-4831c2ce6a0044d9bee9eacefcc0f2bd
Title: Dining room | Kichen baked
*/

import React, { useCallback, useRef, useState } from "react";
import { useGLTF, DragControls, Html, BBAnchor } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";
import { debounce } from "lodash";
import MaterialMenu from "../GeometryHud";
import { useDispatch, useSelector } from "react-redux";
import { setCamControl, setSelectedObj, toggleHud } from "../threeSlice";
import { Button, Card, Icon } from "semantic-ui-react";
import GeometryCard from "../GeometryCard";

export function Kitchen(props) {
  const { nodes, materials } = useGLTF(
    "/models/dining_room__kichen_baked_separate4.glb"
  );

  const seatRef = useRef();
  const tableRef = useRef();
  const carpetRef = useRef();
  const wallRef = useRef();

  const [hovered, hover] = useState(null);
  // Debounce hover a bit to stop the ticker from being erratic
  const debouncedHover = useCallback(debounce(hover, 30), []);
  const over = (name) => (e) => (e.stopPropagation(), debouncedHover(name));

  const dispatch = useDispatch();

  const seatCurrentColor = useSelector(
    (state) => state.three.seat.currentColor
  );
  const seatCurrent = useSelector((state) => state.three.seat);
  const tableCurrentColor = useSelector(
    (state) => state.three.table.currentColor
  );

  const tableCurrent = useSelector((state) => state.three.table);
  const wallCurrent = useSelector((state) => state.three.wall);
  const wallCurrentColor = useSelector(
    (state) => state.three.wall.currentColor
  );

  const hudVisible = useSelector((state) => state.three.hudVisible);
  const selectedObj = useSelector((state) => state.three.selectedObj);
  const camControl = useSelector((state) => state.three.camControl);
  const editMode = useSelector((state) => state.three.editMode);

  return (
    <>
      <group {...props} dispose={null}>
        <Select
          enabled={hovered === "seat" && editMode}
          onPointerOver={over("seat")}
          onPointerOut={() => debouncedHover(null)}
          onPointerDown={(e) => {
            e.stopPropagation();
            // console.log(seatRef.current.name);
            !editMode &&
              props.cameraControlsRef.current?.fitToBox(seatRef.current, true);

            dispatch(setSelectedObj("seat"));
          }}
          onPointerMissed={() => {
            // console.log("missed form seat");
            // dispatch(toggleHud(false));
          }}
        >
          <DragControls
            dragConfig={{ enabled: editMode }}
            axisLock="y"
            onDragStart={() => {
              dispatch(setCamControl(false));
            }}
            onDragEnd={() => {
              dispatch(setCamControl(true));
            }}
          >
            {selectedObj === "seat" && (
              <Html sprite zIndexRange={[2, 0]}>
                {/* <GeometryCard item={seatCurrent} /> */}
                <Card>
                  {!editMode && (
                    <Card.Content>
                      <Card.Header>{seatCurrent.title}</Card.Header>
                      <Card.Meta>
                        <span className="date">
                          Price : {seatCurrent.price}
                        </span>
                      </Card.Meta>
                      <Card.Description>
                        {seatCurrent.description}
                      </Card.Description>
                      <Icon name="linkify" />
                      <a
                        href={seatCurrent.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View product page
                      </a>
                    </Card.Content>
                  )}

                  {editMode && (
                    <Card.Content extra>
                      <Button
                        icon="undo"
                        onClick={() => {
                          seatRef.current.rotation.z += (90 * Math.PI) / 180;
                        }}
                      />
                      <Button
                        icon="redo"
                        onClick={() => {
                          seatRef.current.rotation.z -= (90 * Math.PI) / 180;
                        }}
                      />
                      <Button
                        icon="quidditch"
                        onClick={() => {
                          dispatch(toggleHud(!hudVisible));
                        }}
                      />
                      <Button icon="trash" floated="right" />
                    </Card.Content>
                  )}
                </Card>
              </Html>
            )}

            <mesh
              ref={seatRef}
              castShadow
              receiveShadow
              name="seat"
              geometry={nodes.seat_Leather_0.geometry}
              material={materials.Leather}
              material-color={seatCurrentColor}
              material-emissive={seatCurrentColor}
              // material-side={0}
              //   position={[0.817, 0.598, -1.277]}
              //   rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              //   scale={[0.253, 0.253, 0.027]}
              position={[0.828, 0.589, 0.267]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              scale={[0.253, 0.253, 0.027]}
            ></mesh>
          </DragControls>
        </Select>
        <Select
          enabled={hovered === "table" && editMode}
          onPointerOver={over("table")}
          onPointerOut={() => debouncedHover(null)}
          onPointerDown={(e) => {
            e.stopPropagation();
            // console.log(tableRef.current.name);
            !editMode &&
              props.cameraControlsRef.current?.fitToBox(tableRef.current, true);

            dispatch(setSelectedObj("table"));
          }}
          onPointerMissed={() => {
            // console.log("missed form table");
            // dispatch(toggleHud(false));
          }}
        >
          <DragControls
            dragConfig={{ enabled: editMode }}
            axisLock="y"
            onDragStart={() => {
              dispatch(setCamControl(false));
            }}
            onDragEnd={() => {
              dispatch(setCamControl(true));
            }}
          >
            {selectedObj === "table" && (
              <Html sprite zIndexRange={[2, 0]}>
                <Card>
                  {!editMode && (
                    <Card.Content>
                      <Card.Header>{tableCurrent.title}</Card.Header>
                      <Card.Meta>
                        <span className="date">
                          Price : {tableCurrent.price}
                        </span>
                      </Card.Meta>
                      <Card.Description>
                        {tableCurrent.description}
                      </Card.Description>
                      <Icon name="linkify" />
                      <a
                        href={tableCurrent.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View product page
                      </a>
                    </Card.Content>
                  )}
                  {editMode && (
                    <Card.Content extra>
                      <Button
                        icon="undo"
                        onClick={() => {
                          tableRef.current.rotation.z += (90 * Math.PI) / 180;
                        }}
                      />
                      <Button
                        icon="redo"
                        onClick={() => {
                          tableRef.current.rotation.z -= (90 * Math.PI) / 180;
                        }}
                      />
                      <Button
                        icon="quidditch"
                        onClick={() => {
                          dispatch(toggleHud(!hudVisible));
                        }}
                      />
                      <Button icon="trash" floated="right" />
                    </Card.Content>
                  )}
                </Card>
              </Html>
            )}
            <mesh
              ref={tableRef}
              castShadow
              receiveShadow
              name="table"
              geometry={nodes.table_Material5001_0.geometry}
              material={materials["Material5.001"]}
              material-color={tableCurrentColor}
              material-emissive={tableCurrentColor}
              // material-side={0}
              //   position={[0.831, 0.657, 0.247]}
              //   rotation={[-Math.PI / 2, 0, 0]}
              //   scale={[0.807, 0.807, 0.108]}
              position={[0.831, 0.594, 0.247]}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={[0.807, 0.807, 0.108]}
            />
          </DragControls>
        </Select>
        <mesh
          ref={carpetRef}
          castShadow
          receiveShadow
          geometry={nodes.carpet_Carpet__0.geometry}
          material={materials.Carpet}
          position={[0.816, 0.024, 0.265]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[1.908, 2.45, 0.005]}
          onPointerDown={(e) => {
            e.stopPropagation();
            //   props.cameraControlsRef.current?.fitToBox(carpetRef.current, true);

            // dispatch(toggleHud(false));
            // dispatch(setSelectedObj(null));
          }}
        />

        {/* <Select
          enabled={hovered === "wall"}
          onPointerOver={over("wall")}
          onPointerOut={() => {
            debouncedHover(null);
            console.log("over wall2");
          }}
          onPointerDown={(e) => {
            console.log("over wall");
            e.stopPropagation();
            console.log(wallRef.current.name);
            // props.cameraControlsRef.current?.fitToBox(tableRef.current, true);

            dispatch(setSelectedObj("wall"));
          }}
        > */}

        <BBAnchor anchor={[-0.6, 1, -1]}>
          <Html zIndexRange={[1, 0]} center>
            <Button
              circular
              icon="dot circle outline"
              onClick={() => {
                dispatch(setSelectedObj("wall"));
              }}
            />
          </Html>
          {selectedObj === "wall" && (
            <Html zIndexRange={[2, 0]} sprite>
              <Card>
                {!editMode && (
                  <Card.Content>
                    <Card.Header>{wallCurrent.title}</Card.Header>
                    <Card.Meta>
                      <span className="date">Price : {wallCurrent.price}</span>
                    </Card.Meta>
                    <Card.Description>
                      {wallCurrent.description}
                    </Card.Description>
                    <Icon name="linkify" />
                    <a href={wallCurrent.url} target="_blank" rel="noreferrer">
                      View product page
                    </a>
                  </Card.Content>
                )}
                {editMode && (
                  <Card.Content extra>
                    <Button
                      icon="quidditch"
                      onClick={() => {
                        dispatch(toggleHud(!hudVisible));
                      }}
                    />
                  </Card.Content>
                )}
              </Card>
            </Html>
          )}
        </BBAnchor>
        <mesh
          name="wall"
          ref={wallRef}
          castShadow
          receiveShadow
          geometry={nodes.house_Material009_0.geometry}
          material={materials["Material.004"]}
          material-color={wallCurrentColor}
          material-emissive={wallCurrentColor}
          position={[0, 1.513, -0.003]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[3.962, 3.962, 1.5]}
        />

        {/* </Select> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.house_Material009_0001.geometry}
          material={materials["Material.005"]}
          position={[0, 1.513, -0.003]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[3.962, 3.962, 1.5]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.cabinet_Material015_0.geometry}
          material={materials["Material.015"]}
          position={[3.675, 3.002, 0.459]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.344, 2.492, 0.036]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.window_Material016_0.geometry}
          material={materials["Material.016"]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.floor_floor_0.geometry}
          material={materials.floor}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.jar4_Material017_0.geometry}
          material={materials["Material.017"]}
          position={[3.77, 0.943, 2.193]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.078}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.jar3_Material002_0.geometry}
          material={materials["Material.002"]}
          position={[3.834, 0.95, 2.39]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.083}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.vase_grey001_0.geometry}
          material={materials["grey.001"]}
          position={[0.853, 0.863, 0.641]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.083}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.faucet_Material006_0.geometry}
          material={materials["Material.006"]}
          position={[3.921, 0.95, 1.417]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.002, 0.002, 0.016]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bowl_grey_0.geometry}
          material={materials.grey}
          position={[3.728, 0.991, 2.709]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.165, 0.165, 0.127]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.painting_Material010_0.geometry}
          material={materials["Material.010"]}
          position={[3.885, 1.176, -2.114]}
          rotation={[-Math.PI / 2, 0.148, 0]}
          scale={[0.027, 0.313, 0.313]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.candle_Material013_0.geometry}
          material={materials["Material.013"]}
          position={[3.662, 1.16, -1.438]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.038}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.candle_Material008_0.geometry}
          material={materials.PaletteMaterial001}
          position={[3.662, 1.16, -1.438]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.038}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.candle_Material007_0.geometry}
          material={materials["Material.007"]}
          position={[3.662, 1.16, -1.438]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.038}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.flower_leaf1_0.geometry}
          material={materials.leaf1}
          position={[0.858, 0.883, 0.645]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lighting_Material001_0.geometry}
          material={materials["Material.001"]}
          position={[1.888, 2.435, -0.266]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.027, 0.027, 0.23]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lighting_Material003_0.geometry}
          material={materials["Material.003"]}
          position={[1.888, 2.435, -0.266]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.027, 0.027, 0.23]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lighting_Material014_0.geometry}
          material={materials["Material.014"]}
          position={[1.888, 2.435, -0.266]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.027, 0.027, 0.23]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.IKEA_seat_wood__0.geometry}
          material={materials.wood}
          position={[3.636, 0.32, -3.314]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.175, 0.155, 0.155]}
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.chopping_board1_Material1_0.geometry}
          material={materials.Material1}
          position={[3.883, 0.979, 1.872]}
          rotation={[-1.593, -1.28, -0.023]}
          scale={[0.013, 0.015, 0.013]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.chopping_board2_Material2_0.geometry}
          material={materials.Material2}
          position={[3.916, 0.988, 1.944]}
          rotation={[-1.6, -1.347, -0.03]}
          scale={[0.014, 0.016, 0.014]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.jar1_Material3_0.geometry}
          material={materials.Material3}
          position={[3.752, 0.928, -1.088]}
          rotation={[-Math.PI / 2, 0, -1.875]}
          scale={0.009}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.jar2_Material4_0.geometry}
          material={materials.Material4}
          position={[3.726, 0.972, -1.218]}
          rotation={[-Math.PI / 2, 0, -1.247]}
          scale={0.008}
        />
      </group>
    </>
  );
}

useGLTF.preload("/models/dining_room__kichen_baked_separate4.glb");

import React from "react";
import { useField, useFormikContext } from "formik";
import { FormField, Header, Image, Label } from "semantic-ui-react";
import Select from "react-select";

export default function MySelectArrayInput({ label, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Select
        // {...field}
        {...props}
        className='inverted mini long'
        onChange={(data) => {
          setFieldValue(field.name, data);
        }}
        formatOptionLabel={(item) => (
          <div style={{ display: "flex" }}>
            {item.image.src && (
              <Image
                style={{ width: "35px", height: "35px" }}
                src={item.image.src || null}
                alt='select-image'
              />
            )}

            <Header
              content={item.label}
              style={{ marginTop: "0", marginLeft: "1rem" }}
            />
          </div>
        )}
      />

      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}

import cuid from "cuid";
import {
  updateDocFileUrl,
  updateDocImageUrl,
  updateUserProfilePhoto,
} from "./firestoreService";
import firebase from "../../app/config/firebase";

export function uploadToFirebaseStorage(
  file,
  filename,
  docId,
  childId = null,
  collectionFolder,
  collectionDB,
  workspaceId,
  filenameFixed = false,
  setProgress
) {
  return (dispatch, getState, getFirebase) => {
    // const firebase = getFirebase();
    // const firestore = firebase.firestore();
    function resizedName(fileName, dimensions) {
      const extIndex = fileName.lastIndexOf(".");
      const ext = fileName.substring(extIndex);
      return `${fileName.substring(0, extIndex)}_${dimensions}${".jpeg"}`;
    }
    function delay(t, v) {
      return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t);
      });
    }
    function isPromise(obj) {
      return (
        !!obj &&
        (typeof obj === "object" || typeof obj === "function") &&
        typeof obj.then === "function"
      );
    }

    function keepTrying(triesRemaining, storageRef) {
      if (triesRemaining < 0) {
        return Promise.reject("out of tries");
      }

      return storageRef
        .getDownloadURL()
        .then((url) => {
          return url;
        })
        .catch((error) => {
          switch (error.code) {
            case "storage/object-not-found":
              return delay(2000).then(() => {
                return keepTrying(triesRemaining - 1, storageRef);
              });
            default:
              console.log(error);
              return Promise.reject(error);
          }
        });
    }
    const fileCuid = cuid();

    const imagePath = `/workspaces/${workspaceId}/${collectionFolder}/${docId}/original`;
    console.log("imagePath:  ", imagePath);

    const fileExt = filename.substring(filename.lastIndexOf("."));

    let imageFilename = `${fileCuid}_${filename}`;
    if (filenameFixed) {
      imageFilename = `${docId}${fileExt}`;
    }

    const imageFilePath = `${imagePath}/${imageFilename}`;

    function imageFilePathResized(dimensions) {
      return `${imagePath}/resized/${resizedName(imageFilename, dimensions)}`;
    }

    const currentUser = firebase.auth().currentUser;
    const storageRef = firebase.storage().ref();
    const storageRefResizedSmall = firebase
      .storage()
      .ref()
      .child(imageFilePathResized("300x300"));
    const storageRefResizedLarge = firebase
      .storage()
      .ref()
      .child(imageFilePathResized("1200x1200"));

    // Create file metadata to update
    const newMetadata = {
      cacheControl: "max-age=86400",
    };

    const uploadTask = storageRef.child(imageFilePath).put(file, newMetadata);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log("upload is: " + progress + "% done");
        },
        (error) => {
          console.log("error task", error);
          reject(error);
        },

        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
            keepTrying(10, storageRefResizedSmall).then(
              (downloadResizedSmallUrl) => {
                keepTrying(10, storageRefResizedLarge).then(
                  (downloadResizedLargeUrl) => {
                    dispatch(
                      updateDocImageUrl(
                        collectionDB,
                        childId ? childId : docId,
                        downloadUrl,
                        downloadResizedSmallUrl,
                        downloadResizedLargeUrl,
                        imageFilename
                      )
                    ).then(() => {
                      resolve();
                    });
                  }
                );
              }
            );
          });
        }
      );
    });
  };
}

export function uploadFileToFirebaseStorage(
  file,
  filename,
  docId,
  childId = null,
  collectionFolder,
  collectionDB,
  workspaceId,
  filenameFixed = false,
  field,
  setProgress
) {
  return (dispatch, getState, getFirebase) => {
    // const firebase = getFirebase();
    // const firestore = firebase.firestore();

    const fileCuid = cuid();

    const imagePath = `/workspaces/${workspaceId}/${collectionFolder}/${docId}`;
    console.log("imagePath:  ", imagePath);

    const fileExt = filename.substring(filename.lastIndexOf("."));

    let imageFilename = `${fileCuid}_${filename}`;
    if (filenameFixed) {
      imageFilename = `${docId}${fileExt}`;
    }

    const imageFilePath = `${imagePath}/${imageFilename}`;

    const currentUser = firebase.auth().currentUser;
    const storageRef = firebase.storage().ref();

    const uploadTask = storageRef.child(imageFilePath).put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log("upload is: " + progress + "% done");
        },
        (error) => {
          console.log("error task", error);
          reject(error);
        },

        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
            try {
              await dispatch(
                updateDocFileUrl(
                  collectionFolder,
                  docId,
                  downloadUrl,
                  field,
                  true
                )
              );
              if (childId) {
                await dispatch(
                  updateDocFileUrl(collectionDB, childId, downloadUrl, field)
                );
              }
              resolve();
            } catch (error) {
              console.log("error update URL", error);
              reject(error);
            }
          });
        }
      );
    });
  };
}

export function StoragePathGetDownloadURL(gsPath) {
  return (dispatch, getState, getFirebase) => {
    // const firebase = getFirebase();
    const storageRef = firebase.storage().refFromURL(gsPath);
    return storageRef.getDownloadURL();
  };
}
export function uploadProfileImageToFB(file, filename, setProgress) {
  return (dispatch, getState, getFirebase) => {
    console.log("file blob", file);
    const fileCuid = cuid();
    const imageFilename = `${fileCuid}_${filename}`;

    // const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef
      .child(`/users/${user.uid}/user_images/${imageFilename}`)
      .put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log("upload is: " + progress + "% done");
        },
        (error) => {
          console.log("error task", error);
          reject(error);
        },

        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
            try {
              // await dispatch(updateUserProfilePhoto(downloadUrl));

              resolve(downloadUrl);
            } catch (error) {
              console.log("error update URL", error);
              reject(error);
            }
          });
        }
      );
    });
  };
}

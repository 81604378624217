import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Header, Popup } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";

export default function ProfilePopup({ user, children }) {
  const dispatch = useDispatch();
  return (
    <Popup hoverable trigger={children}>
      <Header.Subheader content={user?.displayName} />
      <Header.Content content={user?.email} />
      <Button
        content='View Profile'
        as={Link}
        to={`/profile/${user?.id}`}
        onClick={() => {
          dispatch(closeModal());
        }}
      />
    </Popup>
  );
}

import React, { useState } from "react";
import { Card, Container } from "semantic-ui-react";
import RoomListItem from "./RoomListItem";

export default function RoomList({ cards }) {
  return (
    <>
      {cards.map((card) => (
        <RoomListItem card={card} key={card.id} />
      ))}
    </>
  );
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  Header,
  Menu,
  Segment,
} from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardColumn from "../../card/CardColumn";

import SubNavBar from "../../nav/SubNavBar";
import SectionDashboard from "../../section/SectionDashboard";
import SectionList from "../../section/SectionList";
import Selection3dList from "./Selection3dList";

export default function Selection3dDashboard() {
  const { spaceId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const [cancelView, setCancelView] = useState(false);
  return (
    <>
      <Menu>
        <Menu.Item>
          <Button
            icon="plus"
            inverted
            color="orange"
            onClick={() => {
              dispatch(
                openModal({
                  modalType: `SectionFormModal`,
                  modalProps: {
                    workspaceId,
                    spaceId,
                    selectedDoc: null,
                    collection: "selection3ds",
                  },
                })
              );
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Header
            style={{ color: "#28292B" }}
            id="fonts"
            inverted
            content={"3D Selection"}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            toggle
            onChange={() => {
              setCancelView(!cancelView);
            }}
          />
        </Menu.Item>
      </Menu>

      <SectionDashboard cancelView={cancelView} sectionId="selection3ds">
        {/* <SectionList> */}

        <CardColumn collection={"selection3ds"}>
          <Selection3dList />
        </CardColumn>

        {/* </SectionList> */}
      </SectionDashboard>
    </>
  );
}

import {
  CameraControls,
  DragControls,
  useGLTF,
  useHelper,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "semantic-ui-react";
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
  MeshBVHHelper,
  StaticGeometryGenerator,
  MeshBVH,
} from "three-mesh-bvh";

import * as THREE from "three";

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

function checkCollision(item1, item2) {
  if (item1.isMesh && item2.isMesh) {
    // console.log("check1", item1, item2);
    // item1?.geometry?.computeBoundsTree();
    // console.log("check2");
    // item2?.geometry?.computeBoundsTree();

    const transformMatrix = new THREE.Matrix4()
      .copy(item1.matrixWorld)
      .invert()
      .multiply(item2.matrixWorld);

    // item1?.current?.traverse(function (child) {
    //   if (child?.geometry?.index) {
    //     //   console.log("child", child);
    //     const hit = child?.geometry?.boundsTree.intersectsGeometry(
    //       item2?.geometry,
    //       transformMatrix
    //     );
    //     return hit;
    //   }
    // });

    const hit = item1?.geometry?.boundsTree.intersectsGeometry(
      item2?.geometry,
      transformMatrix
    );

    return hit;
  }
  return false;
}

export default function GeoSandbox() {
  const [canvasScene, setCanvasScene] = useState(null);

  //add the url string of the glb below
  const { scene } = useGLTF("");
  function Model(props) {
    const meshRef = useRef();

    const [hovered, setHover] = useState(false);
    const [active, setActive] = useState(false);
    const [selected, setSelected] = useState(null);
    const [target, setTarget] = useState(null);

    let boxModel = canvasScene?.getObjectByName("box");
    let sphereModel = canvasScene?.getObjectByName("sphere");

    const meshesList = [];

    useEffect(() => {
      console.log("effect..");
      if (meshRef.current.children) {
        meshRef.current?.traverse(function (child) {
          if (child?.geometry?.index) {
            meshesList.push(child);
            //    console.log("child", child);
            //   child.geometry.computeBoundsTree();
          }
        });
      }
      console.log("meshesList", meshesList);

      const generator = new StaticGeometryGenerator([...meshesList]);
      generator.attributes = ["position"];
      const mergedGeometry = generator.generate();
      mergedGeometry.computeBoundsTree();

      console.log("merged geometry", mergedGeometry);

      if (meshRef.current.geometry.index) {
        meshRef.current.geometry.computeBoundsTree();
      } else {
        meshRef.current?.traverse(function (child) {
          if (child?.geometry?.index) {
            //    console.log("child", child);
            // child.geometry.computeBoundsTree();
          }
        });
      }

      console.log(
        "compute"
        // meshRef.current?.children[0]?.children[0].geometry
      );

      //   if (meshRef.current?.children[0]?.children[0]) {
      //   meshRef.current?.children[0].map((item)=>{
      //       item.geometry.computeBoundsTree()

      //   })

      //   }
    }, []);

    useHelper(meshRef, MeshBVHHelper);
    // useHelper(meshRef.current?.children[0].children[0], THREE.BoxHelper, "red");

    // useHelper(meshRef?.current, THREE.BoxHelper, "red");

    return (
      <DragControls
        onDrag={() => {
          // checkCollision()

          console.log("test::", boxModel?.name, sphereModel?.name);
          // console.log(checkCollision(boxModel, sphereModel));
        }}
      >
        <mesh
          name={props.name}
          {...props}
          ref={meshRef}
          // scale={active ? 1.5 : 1}
          //   onClick={(event) => setActive(!active)}
          onClick={(event) => {
            console.log(meshRef.current);
          }}
          //   onPointerDown={() => {
          //     setSelected(meshRef.current);

          //     selected?.name === "box"
          //       ? setTarget(canvasScene.getObjectByName("sphere"))
          //       : setTarget(canvasScene.getObjectByName("box"));
          //     console.log("selected:", selected?.name, "target:", target?.name);
          //   }}

          onPointerOver={(event) => setHover(true)}
          onPointerOut={(event) => setHover(false)}
        >
          {props.type === "box" && <boxGeometry args={[1, 1, 1]} />}
          {/* {props.type === "sphere" && <sphereGeometry />} */}
          {props.type === "sphere" && <primitive object={scene.clone()} />}

          <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
        </mesh>
      </DragControls>
    );
  }
  return (
    <Container
      fluid
      style={{
        background: "white",
        height: "100vh",
      }}
    >
      <Canvas
        onCreated={({ scene }) => {
          setCanvasScene(scene);
        }}
      >
        {/* <CameraControls /> */}
        <ambientLight intensity={Math.PI / 2} />
        <spotLight
          position={[10, 10, 10]}
          angle={0.15}
          penumbra={1}
          decay={0}
          intensity={Math.PI}
        />
        <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
        <Model position={[-1.2, 0, 0]} type={"box"} name="box" />
        <Model position={[1.2, 0, 0]} type={"sphere"} name="sphere" />
      </Canvas>
    </Container>
  );
}

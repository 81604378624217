import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import ProfileForm from "./ProfileForm";

export default function ProfileFormModal({ profile }) {
  return (
    <ModalWrapper size='small'>
      <ProfileForm profile={profile} />
    </ModalWrapper>
  );
}

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import HomePage from "../../features/home/HomePage";

import WorkspaceDashboard from "../../features/workspace/WorkspaceDashboard";

import ModalManager from "../common/modals/ModalManager";
import Sandbox from "../../features/sandbox/Sandbox";
import { ToastContainer } from "react-toastify";
import AccountPage from "../../features/auth/AccountPage";
import LoadingComponent from "./LoadingComponent";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import ProfilePage from "../../features/profiles/ProfilePage";
import UnauthPage from "../../features/auth/UnauthPage";
import WorkspaceDetailPage from "../../features/workspace/WorkspaceDetailPage";
import SpaceDashboard from "../../features/space/SpaceDashboard";
import SpaceDetailPage from "../../features/space/SpaceDetailPage";
import Sketcth2dDashboard from "../../features/design/sketch2d/Sketcth2dDashboard";
import LayoutDashboard from "../../features/design/layout/LayoutDashboard";
import Sketcth3dDashboard from "../../features/design/sketch3d/Sketch3dDashboard";
import VisualDashboard from "../../features/design/visual/VisualDashboard";
import UserDashboard from "../../features/user/UserDashboard";
import Layout from "../../features/nav/Layout";
import Home from "../../features/profiles/Home";
import PresentationDashboard from "../../features/design/presentation/PresentationDashboard";
import SelectionDashboard from "../../features/design/selection/SelectionDashboard";
import SupplierDashboard from "../../features/library/supplier/SupplierDashboard";
import ProductDashboard from "../../features/library/product/ProductDashboard";
import RoomDashboard from "../../features/design/room/RoomDashboard";
import MyProfilePage from "../../features/profiles/MyProfilePage";
import GeometryViewer from "../../features/geometry/GeometryViewer";
import GeoSandbox from "../../features/geometry/GeoSandbox";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  if (!isLoaded(auth && profile))
    return <LoadingComponent content="Loading App..." />;
  return children;
}

// function PrivateRoute({ component, path }) {
//   const auth = useSelector((state) => state.firebase.auth);

//   return isLoaded(auth) && !isEmpty(auth) ? (
//     <Route exact path={path}>
//       {component}
//     </Route>
//   ) : (
//     <>
//       <Redirect to='/unauth' />
//     </>
//   );
// }

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/unauth" />
        )
      }
    />
  );
};

export default function App() {
  // const { initialized } = useSelector((state) => state.async);
  // if (!initialized) return <LoadingComponent content='Loading App...' />;

  return (
    <>
      <Router>
        <AuthIsLoaded>
          <ModalManager />
          <ToastContainer position="bottom-right" />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/unauth" component={UnauthPage} />
          <Route
            path="/(.+)"
            render={() => (
              <>
                <Switch>
                  <Route exact path="/sandbox" component={Sandbox} />
                  <Route exact path="/geosandbox" component={GeoSandbox} />
                  <PrivateRoute path="/account" component={AccountPage} />
                  <PrivateRoute path="/home" component={Home} />
                  <PrivateRoute
                    exact
                    path="/myprofile"
                    component={MyProfilePage}
                  />
                  <PrivateRoute
                    exact
                    path="/profile/:profileId"
                    component={ProfilePage}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces"
                    component={WorkspaceDashboard}
                  />

                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId"
                    component={WorkspaceDetailPage}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/users"
                    component={UserDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces"
                    component={SpaceDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId"
                    component={SpaceDetailPage}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/sketch2d"
                    component={Sketcth2dDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/layout"
                    component={LayoutDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/sketch3d"
                    component={Sketcth3dDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/visual"
                    component={VisualDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/presentation"
                    component={PresentationDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/selection"
                    component={SelectionDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/room"
                    component={RoomDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/spaces/:spaceId/designer"
                    component={GeometryViewer}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/suppliers"
                    component={SupplierDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/workspaces/:workspaceId/suppliers/:supplierId"
                    component={ProductDashboard}
                  />
                  <Route render={() => <Redirect to={{ pathname: "/" }} />} />
                </Switch>
              </>
            )}
          />
        </AuthIsLoaded>
      </Router>
    </>
  );
}

import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import GeometryBasicViewer from "./GeometryBasicViewer";

export default function GeometryBasicViewerModal({ data }) {
  return (
    <ModalWrapper size="small">
      <GeometryBasicViewer card={data} />
    </ModalWrapper>
  );
}

import React from "react";
import { Card, Container } from "semantic-ui-react";
import Selection3dListItem from "./Selection3dListItem";

export default function Selection3dList({ cards }) {
  return (
    <>
      <Container style={{ marginTop: "1rem" }}>
        <Card.Group
          stackable
          itemsPerRow={1}
          style={{
            // backgroundColor: "grey",
            // height: "calc(100vh-1em)",
            height: "calc(100vh - 23rem)",

            overflowY: "scroll",
            scrollSnapType: "y mandatory",
          }}
        >
          {cards.map((card) => (
            <Selection3dListItem card={card} key={card.id} />
          ))}
        </Card.Group>
      </Container>
    </>
  );
}

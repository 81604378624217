import React from "react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import Selection3dForm from "./Selection3dForm";

export default function Selection3dFormModal({
  workspaceId,
  spaceId,
  supplierId,
  section,
  selectedDoc,
  collection,
}) {
  return (
    <ModalWrapper size="small">
      <Selection3dForm
        workspaceId={workspaceId}
        spaceId={spaceId || null}
        supplierId={supplierId}
        sectionId={section?.id}
        selectedDoc={selectedDoc}
        collection={collection}
      />
    </ModalWrapper>
  );
}

import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Header,
  Image,
  Label,
  Progress,
  Segment,
} from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import {
  storageDeleteFolder,
  updateUserProfile,
} from "../../app/firestore/firestoreService";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../app/common/util/cropImage";
import { uploadProfileImageToFB } from "../../app/firestore/firebaseService";
import MyImageCropInput from "../../app/common/form/MyImageCropInput";

const initialValues = {
  displayName: "",
  position: "",
};

const validationSchema = Yup.object({
  displayName: Yup.string().required("you must provide a display name"),
});

export default function ProfileForm({ profile }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageCropped, setImageCropped] = useState(null);
  const [imageCroppedForDisplay, setImageCroppedForDisplay] = useState(null);
  const [progress, setProgress] = useState(0);
  // const [resize, setResize] = useState({ width: 200, height: 200 });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log("complete");
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const imageAfterCrop = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
        filename
      );
      console.log("done", imageAfterCrop, filename);
      setImageCroppedForDisplay(URL.createObjectURL(imageAfterCrop));
      setImageCropped(imageAfterCrop);
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels, rotation]);

  return (
    <Segment basic clearing>
      {progress > 0 && (
        <Progress percent={progress} size='tiny' color='orange' />
      )}
      <Formik
        enableReinitialize
        initialValues={profile ? profile : initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          const { file, ...valueWithNoFile } = values;
          if (imageCropped) {
            const folderPath = `users/${auth.uid}/user_images`;
            dispatch(storageDeleteFolder(folderPath))
              .then(() => {
                console.log("old profile image deleted ...");
                dispatch(
                  uploadProfileImageToFB(imageCropped, filename, setProgress)
                ).then((downloadURL) => {
                  valueWithNoFile.photoURL = downloadURL;
                  console.log("values", valueWithNoFile);

                  dispatch(updateUserProfile(valueWithNoFile))
                    .then(() => {
                      dispatch(closeModal());
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      setErrors({ submit: error.message });
                      setSubmitting(false);
                    });
                });
              })
              .catch((error) => {
                setErrors({ submit: error.message });
                setSubmitting(false);
              });
          } else {
            dispatch(updateUserProfile(valueWithNoFile))
              .then(() => {
                dispatch(closeModal());
                setSubmitting(false);
              })
              .catch((error) => {
                setErrors({ submit: error.message });
                setSubmitting(false);
              });
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className='ui form'>
            <MyTextInput name='displayName' placeholder='Display Name' />
            <MyTextInput name='position' placeholder='Position Title' />
            <MyImageCropInput
              id='file'
              type='file'
              accept='image/*'
              name='file'
              placeholder='Browse Photo'
              setFilename={setFilename}
              setImage={setImage}
              setImageCroppedForDisplay={setImageCroppedForDisplay}
              setImageCropped={setImageCropped}
            />

            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              positive
              content='Submit'
              style={{ backgroundColor: "#ffa600", color: "white" }}
            />
          </Form>
        )}
      </Formik>
      {filename && (
        <>
          {!imageCroppedForDisplay && (
            <Header
              floated='right'
              size='tiny'
              content='Press crop before submitting ...'
            />
          )}
          <Button content='Crop' onClick={showCroppedImage} />

          <Segment basic style={{ height: "200px", width: "100%" }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              cropShape='round'
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <Image src={imageCroppedForDisplay} style={{ height: "200px" }} />
          </Segment>
        </>
      )}
    </Segment>
  );
}

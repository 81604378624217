import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CardForm from "./CardForm";

export default function CardFormModal({
  workspaceId,
  spaceId,
  supplierId,
  section,
  selectedDoc,
  collection,
}) {
  console.log("section.id", section?.id);
  return (
    <ModalWrapper size='small'>
      <CardForm
        workspaceId={workspaceId}
        spaceId={spaceId || null}
        supplierId={supplierId}
        sectionId={section?.id}
        selectedDoc={selectedDoc}
        collection={collection}
      />
    </ModalWrapper>
  );
}

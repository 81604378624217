import React, { useRef, useState } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { Hud, OrthographicCamera, Environment } from "@react-three/drei";
import { TextureLoader, MathUtils } from "three";
import { useDispatch, useSelector } from "react-redux";
import {
  setHudOpacity,
  setSeatColor,
  setTableColor,
  setWallColor,
} from "./threeSlice";

function Button({ id, color, position, roughness }) {
  const ref = useRef();
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const selectedObj = useSelector((state) => state.three.selectedObj);
  const hudOpacity = useSelector((state) => state.three.hudOpacity);
  const hudVisible = useSelector((state) => state.three.hudVisible);

  useFrame((_, delta) => {
    ref.current.scale.y =
      ref.current.scale.x =
      ref.current.scale.z =
        MathUtils.lerp(ref.current.scale.y, hovered ? 1.5 : 1, 0.25);
    hovered && ref.current.rotateY(delta * 5);

    ref.current.material.opacity =
      hudVisible && MathUtils.lerp(ref.current.material.opacity, 1, 0.1);
  });

  return (
    <mesh
      ref={ref}
      position={position}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onPointerDown={(e) => {
        // selectedObj === "seat"
        //   ? dispatch(setSeatColor(color))
        //   : dispatch(setTableColor(color));
        e.stopPropagation();
        switch (selectedObj) {
          case "seat": {
            dispatch(setSeatColor(color));
            break;
          }
          case "table": {
            dispatch(setTableColor(color));
            break;
          }
          case "wall": {
            dispatch(setWallColor(color));
            break;
          }
          default: {
            console.log("nothing to change...");
            break;
          }
        }
      }}
    >
      <sphereGeometry />
      <meshStandardMaterial
        color={color}
        // emissive={color}
        roughness={roughness}
        envMapIntensity={1.5}
        transparent={true}
        opacity={0}
      />
    </mesh>
  );
}

export default function MaterialMenu() {
  const seatColors = useSelector((state) => state.three.seat.availableColors);
  const tableColors = useSelector((state) => state.three.table.availableColors);
  const wallColors = useSelector((state) => state.three.wall.availableColors);
  const selectedObj = useSelector((state) => state.three.selectedObj);
  let colorList;
  switch (selectedObj) {
    case "seat": {
      colorList = seatColors;
      break;
    }
    case "table": {
      colorList = tableColors;

      break;
    }
    case "wall": {
      colorList = wallColors;

      break;
    }
    default: {
      //temp solution:to avoid crash if select object that does not have colors to choose from
      colorList = undefined;
      break;
    }
  }

  if (colorList !== undefined) {
    return (
      <Hud renderPriority={2}>
        <OrthographicCamera makeDefault position={[0, 0, 2]} zoom={50} />
        <Environment preset="forest" />
        <Button
          id={0}
          color={colorList[0]}
          position={[-6, -7, 0]}
          roughness={0.5}
        />
        <Button
          id={1}
          color={colorList[1]}
          position={[-3, -7, 0]}
          roughness={0.5}
        />
        <Button
          id={2}
          color={colorList[2]}
          position={[-0, -7, 0]}
          roughness={0.5}
        />
        <Button
          id={3}
          color={colorList[3]}
          position={[3, -7, 0]}
          roughness={0.5}
        />
        <Button
          id={4}
          color={colorList[4]}
          position={[6, -7, 0]}
          roughness={0.5}
        />
      </Hud>
    );
  } else {
    return;
  }
}

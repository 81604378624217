import { createSlice } from "@reduxjs/toolkit";

export const tableSlice = createSlice({
  name: "table",
  initialState: { value: { editRowId: null } },
  reducers: {
    setEditRowId: (state, action) => {
      state.value.editRowId = action.payload;
    },
  },
});

export const { setEditRowId } = tableSlice.actions;
export default tableSlice.reducer;

import { loadWorkspaces } from "../../features/workspace/workspaceActions";
import { verifyAuth } from "../../features/auth/authActions";

import { configureStore } from "@reduxjs/toolkit";
import { actionTypes, firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import testReducer from "../../features/sandbox/testReducer";
import modalReducer from "../common/modals/modalReducer";
import authReducer from "../../features/auth/authReducer";
import asyncReducer from "../async/asyncReducer";
import workspaceReducer from "../../features/workspace/workspaceReducer";
import spaceReducer from "../../features/space/spaceReducer";
import userReducer from "../../features/user/userReducer";
import layoutSlice from "../layout/layoutSlice";
import tableSlice from "../../features/table/tableSlice";
import roomSlice from "../../features/design/room/roomSlice";
import threeSlice from "../../features/geometry/threeSlice";
import usersSlice from "../../features/user/usersSlice";

export const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    test: testReducer,
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    workspace: workspaceReducer,
    space: spaceReducer,
    user: userReducer,
    users: usersSlice,
    layout: layoutSlice,
    table: tableSlice,
    room: roomSlice,
    three: threeSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store.dispatch(verifyAuth());
//to be deleted from here for test only
store.dispatch(loadWorkspaces());
// end of delete

export default store;

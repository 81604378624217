import { format } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Grid, Header, Image } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";

export default function MyProfilePage() {
  const { profile } = useSelector((state) => state.firebase);
  const dispatch = useDispatch();

  return (
    <>
      <Container style={{ marginTop: 90, minHeight: "100vh" }}>
        <Grid centered textAlign='center' columns={1}>
          <Grid.Row>
            <Header color='orange' inverted size='huge' content='My Profile' />
          </Grid.Row>
          <Grid.Row>
            <Image
              centered
              avatar
              size='medium'
              src={profile.photoURL || "/assets/user.png"}
            />
          </Grid.Row>

          <Grid.Row>
            <Header inverted size='huge' content={profile.displayName} />
          </Grid.Row>
          <Grid.Row>
            <Header color='grey' size='medium' content={profile.position} />
          </Grid.Row>
          <Grid.Row>
            <Header color='grey' size='tiny' content={profile.email} />
          </Grid.Row>
          <Grid.Row>
            <Header
              color='grey'
              size='tiny'
              content={`Member since: ${format(
                profile.createdAt.toDate(),
                "MMMM d, yyyy"
              )}`}
            />
          </Grid.Row>
          <Grid.Row>
            <Button
              circular
              icon='edit'
              content='Edit My Profile'
              inverted
              color='orange'
              onClick={() => {
                dispatch(
                  openModal({
                    modalType: "ProfileFormModal",
                    modalProps: {
                      profile,
                    },
                  })
                );
              }}
            />
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
}

import React from "react";
import { Comment, Image, Item, Segment } from "semantic-ui-react";
import { formatDistance } from "date-fns";
import { useSelector } from "react-redux";
import ProfilePopup from "../profiles/ProfilePopup";

export default function CommentListItem({ comment }) {
  const users = useSelector((state) => state.users.workspace);
  const user = users
    ? users.find((item) => item.id === comment.createdBy)
    : null;

  return (
    <Comment>
      <ProfilePopup user={user}>
        <Comment.Avatar src={user?.photoURL || "/assets/user.png"} />
      </ProfilePopup>

      <Comment.Content>
        <Comment.Author as='a'>{user?.displayName}</Comment.Author>
        <Comment.Metadata>
          {comment.createdAt && (
            <div>{formatDistance(comment.createdAt.toDate(), new Date())}</div>
          )}
        </Comment.Metadata>
        <Comment.Text>{comment.comment}</Comment.Text>
        <Image
          as='img'
          loading='lazy'
          rounded
          style={{ width: "100%" }}
          src={comment.imageResizedLargeUrl || null}
        />

        <Comment.Actions>
          <Comment.Action>Reply</Comment.Action>
        </Comment.Actions>
      </Comment.Content>
    </Comment>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Image } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import {
  setCamControl,
  setSelectedObj,
  setShapesOnCanvas,
  toggleHud,
} from "../../geometry/threeSlice";

export default function ProductMarketListItem({
  card,
  direction = "vertical",
  extra = false,
  compact = false,
}) {
  const dispatch = useDispatch();
  const addModel = (card) => {
    console.log("uuuu", card.glbUrl);
    console.log();

    dispatch(setShapesOnCanvas(card));
  };

  return (
    <>
      <Card className={direction} fluid>
        {direction === "vertical" && (
          <>
            {card.imageResizedSmallUrl ? (
              <div className="img-ratio">
                <Image
                  size="medium"
                  src={card.imageResizedSmallUrl}
                  //   wrapped
                  ui={false}
                  onClick={() => {}}
                />
              </div>
            ) : compact ? (
              <></>
            ) : (
              <div className="img-ratio">
                <Image />
              </div>
            )}
          </>
        )}
        <Card.Content>
          <Card.Header>{card.title}</Card.Header>
          <Card.Description>{card.description}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button
            compact
            icon="add"
            floated="right"
            onClick={() => {
              console.log(card.glbUrl);
              addModel(card);
              dispatch(setSelectedObj(null));
            }}
          />
          <Button
            compact
            icon="desktop"
            onClick={() => {
              dispatch(setSelectedObj(null));
              dispatch(toggleHud(false));
              dispatch(
                openModal({
                  modalType: "GeometryBasicViewerModal",
                  modalProps: { data: card, collectionDB: "products" },
                })
              );
            }}
          />
        </Card.Content>
      </Card>
    </>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Item,
  Menu,
  Segment,
} from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { Link } from "react-router-dom";
import { openModal } from "../../app/common/modals/modalReducer";
import SubNavBar from "../nav/SubNavBar";
import { dateDuration, getDate } from "../../app/common/util/util";

export default function SpaceDetailPage() {
  const { spaceId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const selectedSpace = useSelector(
    (state) => state.firestore.ordered[`spaces/${spaceId}`]
  );

  return (
    <>
      {isLoaded(selectedSpace) && !isEmpty(selectedSpace) ? (
        <>
          <SubNavBar>
            <Menu.Item>
              <Button
                circular
                icon="edit"
                inverted
                color="orange"
                onClick={() => {
                  // console.log(selectedSpace[0]);
                  dispatch(
                    openModal({
                      modalType: "SpaceModal",
                      modalProps: {
                        workspaceId,
                        selectedSpace,
                      },
                    })
                  );
                }}
              />
            </Menu.Item>
          </SubNavBar>
          <Container style={{ marginTop: 90, minHeight: "100vh" }}>
            <Grid>
              <Grid.Column width="16">
                <Segment.Group>
                  <Segment
                    basic
                    attached="top"
                    style={{
                      padding: "0",
                      filter: "brightness(80%)",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${
                        selectedSpace[0].imageResizedLargeUrl || null
                      })`,
                      width: "100%",
                      height: "300px",
                    }}
                  ></Segment>
                  <Segment
                    basic
                    style={{
                      position: "absolute",
                      bottom: "5%",
                      left: "5%",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <Item.Group>
                      <Item>
                        <Header as="h3" style={{ color: "white" }}>
                          BUA
                          <Header.Subheader style={{ color: "white" }}>
                            <NumberFormat
                              value={selectedSpace[0].BUA || "--"}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={" sq.ft."}
                            />
                          </Header.Subheader>
                        </Header>
                      </Item>
                      <Item>
                        <Header as="h3" style={{ color: "white" }}>
                          Budget
                          <Header.Subheader style={{ color: "white" }}>
                            <NumberFormat
                              value={selectedSpace[0].budget || "--"}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"AED "}
                            />
                          </Header.Subheader>
                        </Header>
                      </Item>
                    </Item.Group>
                  </Segment>
                  <Segment>
                    <Grid
                      columns={4}
                      stackable
                      divided
                      className="very compact centered "
                    >
                      <Grid.Row>
                        <Grid.Column>
                          <Header content="Design" size="medium" />
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h5">
                            Start
                            <Header.Subheader
                              content={`${getDate(
                                selectedSpace[0].designStartDate
                              )}`}
                            />
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h5">
                            Duration
                            <Header.Subheader
                              content={`${dateDuration(
                                selectedSpace[0].designStartDate,
                                selectedSpace[0].designEndDate
                              )}`}
                              size="medium"
                            />
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h5">
                            End
                            <Header.Subheader
                              content={`${getDate(
                                selectedSpace[0].designEndDate
                              )}`}
                            />
                          </Header>
                        </Grid.Column>

                        <Grid.Column>
                          <Header content="Construction" size="medium" />
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h5">
                            <Header.Subheader
                              content={`${getDate(
                                selectedSpace[0].constructionStartDate
                              )}`}
                            />
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h5">
                            <Header.Subheader
                              content={`${dateDuration(
                                selectedSpace[0].constructionStartDate,
                                selectedSpace[0].constructionEndDate
                              )}`}
                            />
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h5">
                            <Header.Subheader
                              content={`${getDate(
                                selectedSpace[0].constructionEndDate
                              )}`}
                            />
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Segment.Group>

                {selectedSpace[0]?.geoLocation?.latitude && (
                  <Segment.Group>
                    <Segment style={{ padding: "0" }}>
                      {/* style from: https://mapstyle.withgoogle.com */}
                      <StaticGoogleMap
                        style={`element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=480x360`}
                        size="1200x200"
                        className="img-map-static"
                        zoom="13"
                        format="png"
                        scale="2"
                        apiKey={process.env.REACT_APP_API_KEY}
                      >
                        <Marker
                          location={`${selectedSpace[0].geoLocation.latitude},${selectedSpace[0].geoLocation.longitude}`}
                          color="orange"
                        />
                      </StaticGoogleMap>
                    </Segment>

                    <Segment
                      basic
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <Item.Group>
                        <Item>
                          <Header
                            style={{ color: "orange" }}
                            as="a"
                            target="blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${selectedSpace[0].geoLocation.latitude},${selectedSpace[0].geoLocation.longitude}`}
                          >
                            Go To Location
                          </Header>
                        </Item>
                      </Item.Group>
                    </Segment>
                  </Segment.Group>
                )}
              </Grid.Column>
            </Grid>
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

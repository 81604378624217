import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import { Button, Card, Container, Grid, Header, Menu } from "semantic-ui-react";
import ProductMarketList from "./ProductMarketList";

// import SpaceListItemPlaceholder from "./SpaceListItemPlaceholder";

export default function ProductMarketDashboard() {
  const { workspaceId, spaceId } = useParams();
  const dispatch = useDispatch();

  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  let productsQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    productsQuery = {
      collection: "products",
      orderBy: ["createdAt", "desc"],
      storeAs: `products/${workspaceId}`,
      where: [
        // ["type", "==", section.selectionType],
        // ["type", "==", "chair"],
        ["supplierId", "==", "Z5aQJXRnliAJX1AXkX9e"],
        ["workspaceId", "==", workspaceId],
        ["isCancelled", "==", false],
      ],
    };
  } else {
    productsQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
    };
  }

  useFirestoreConnect(() => [productsQuery]);

  const products = useSelector((state) => state.firestore.ordered[`products/${workspaceId}`]);
  return (
    <>
      {isLoaded(products) && !isEmpty(products) ? (
        <>
          <ProductMarketList cards={products} />
        </>
      ) : (
        //   <Header content='test' />
        <></>
      )}
    </>
  );
}
